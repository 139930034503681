import React, { useState } from 'react'
import styles from './CreateCategory.module.css'

const CreateCategoryModal = ({ setIsOpen, specificCat, createJobCategory, categoryData, type, editJobCategory, imageUrl }) => {
  const [formData, setFormData] = useState({
    name: categoryData?.type === "edit" ? categoryData?.status?.name : "",
    file: categoryData?.type === "edit" ? categoryData?.status?.cat_picture_data?.id : null,
    parentId: categoryData ? categoryData?.status?.parent_detail?.id : 0,
  })

  const [selectedImage, setSelectedImage] = useState(
    categoryData?.type === "edit"
      ? imageUrl + categoryData?.status?.cat_picture_data?.file_name
      : null
  );

  const handleInputChange = (e) => {
    const { id, value, files } = e.target;
    if (id === 'file') {
      const file = files[0];
      setFormData((prevData) => ({
        ...prevData,
        [id]: file,
      }));

      // Update the selected image preview
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(type === "create"){
      createJobCategory(formData);
    }else {
      editJobCategory(formData)
    }
    setFormData({
      name: "",
      file: null,
      parentId: ""
    });
  };

  return (
    <React.Fragment>
      <div className="modal-backdrop fade show"></div>
      <div className="modal d-inline-block" id="exampleModal" role="dialog" aria-hidden="false">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{`Create Category`}</h5>
              <button type="button" onClick={() => setIsOpen(false)} className={`${styles.closes} close`} data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body m-2">
              <form onSubmit={handleSubmit}>
                <div className={`${styles.form} form-group`}>
                  <label htmlFor="name">Job category</label>
                  <input type="text" className={`${styles.input} form-control`} id="name" value={formData?.name} placeholder="Enter job category name" onChange={handleInputChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="file">Select the Image</label>
                  <input type="file" className={`${styles.input} form-control`} id="file" accept="image/*" onChange={handleInputChange} />
                  {selectedImage && (
                    <img className={`${styles.img}`} src={selectedImage} alt="Selected Image" />
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">Select Parent Id</label>
                  <select
                    className={`${styles.input} form-control`}
                    id="parentId"
                    value={formData?.parentId}
                    onChange={handleInputChange}
                  >
                    <option value={0}>Select Parent id</option>
                    {specificCat.map((item, index) =>
                      <option key={index} value={item?.id}>{item?.name}</option>
                    )}
                  </select>
                </div>
                <div className={`${styles.formBtn}`}>
                  {type === "create" ?
                    <div className='d-inline-flex gap-2'>
                      <button className={`${styles.closeBtns}`} onClick={() => setIsOpen(false)} >Close</button>
                      <button type="submit" className={`${styles.btns}`}>Create</button>
                    </div>
                    :
                    <div className='d-inline-flex gap-2'>
                      <button className={`${styles.closeBtns}`} onClick={() => setIsOpen(false)} >Close</button>
                      <button type="submit" className={`${styles.btns}`}>Edit</button>
                    </div>
                  }
                </div>
              </form>
            </div>
            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CreateCategoryModal