import axios from "axios";
import { ApiUrls } from "../ApiUrls/apiUrls";
import { axiosInstance } from "../ApiUrls/Interceptors";


/**
 * 
 * @param {*} credentials 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Login the users 
 */
export const login = async (credentials) => {
  try {
    const result = await axios.post(ApiUrls.LOGIN, credentials);
    return {
      success: true,
      status: result?.data?.status,
      data: result?.data,
    }
  } catch (error) {
    return {
      status: false,
      error: error?.response?.data?.message,
    }
  }
}


/**
 * @param {Object} filters 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get all the users List  
 */
export const getUsers = async (filters) => {
  try {
    const result = await axiosInstance.get(ApiUrls.GET_USERS, {
      params: filters
    });

    return {
      status: true,
      rows: result?.data?.data?.list?.count,
      data: result?.data?.data?.list?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}

/**
 * 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get Profile data
 */
export const getProfile = async () => {
  try {
    const result = await axiosInstance.get(ApiUrls.GET_PROFILE);

    return {
      status: true,
      data: result?.data?.data,
      msg: result?.data?.message,
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message,
    }
  }
}

/**
 * 
 * @param {Object} data 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Update the user status Active or In-Active
 */
export const updateUserState = async (data) => {
  try {
    const result = await axiosInstance.post(ApiUrls.UPDATE_USERS_STATUS, data);

    return {
      status: true,
      data: result?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}

/**
 * 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get all Const Data related to User
 */
export const getUserConstData = async () => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_USERS_CONST_DATA}`);

    return {
      status: true,
      data: result?.data?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}


export const uploadfile = async (data) => {
  try {
    const result = await axiosInstance.post(ApiUrls.UPLOAD_IMAGE, data);

    return {
      status: true,
      data: result?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}