import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import { User } from '../pages/User/User';
import { Jobs } from '../pages/Jobs/Jobs';
import { Employer } from '../pages/Employer/Employer';
import { Purchasers } from '../pages/Purchasers/Purchasers';
import { Courses } from '../pages/Courses/Courses';
import { Analytics } from '../pages/Analytics/Analytics';
import { Alert } from '../pages/Alert/Alert';
import { BillingCourse } from '../pages/BillingCourse/BillingCourse';
import { OrderSummery } from '../pages/OrderSummery/OrderSummery';
import { CustomerSummery } from '../pages/CustomerSummery/CustomerSummery';
import { Login } from '../pages/Login/Login';
import Category from '../pages/Category/Category';
import ExclusiveService from '../pages/ExclusiveService/ExclusiveService';

export const PublicRoutes = () => {
  return (
        <React.Fragment>
            <Routes>
                <Route path="/" element={<Dashboard />}></Route>
                <Route path="/overview" element={<Dashboard />}></Route>
                <Route path="/analytics" element={<Analytics />}></Route>
                <Route path="/category" element={<Category />}></Route>
                <Route path="/exclusive-service" element={<ExclusiveService />}></Route>
                <Route path="/courses" element={<Courses />}></Route>
                <Route path="/jobs" element={<Jobs />}></Route>
                <Route path="/billing-all-purchasers" element={<Purchasers />}></Route>
                <Route path="/billing-employer" element={<Employer />}></Route>
                <Route path="/billing-courses" element={<BillingCourse />}></Route>
                <Route path="/user" element={<User />}></Route>
                <Route path="/alert" element={<Alert />}></Route>
                <Route path="/order-summary/:orderId" element={<OrderSummery />}></Route>
                <Route path="/customer-detail/:id" element={<CustomerSummery />}></Route>
                <Route path="/login" element={<Login />}></Route>
                <Route path="*" element={<Dashboard />}></Route>
            </Routes>
        </React.Fragment>
  )
}