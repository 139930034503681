import React, { useState } from 'react'
import styles from './ToggleButton.module.css'

const ToggleButton = ({onToggle, defaultValue}) => {
  const [on, setOn] = useState(defaultValue);

  return (
    <div>
      <div class="form-check form-switch">
        <input class={`${styles.onToggle} form-check-input`} type="checkbox" checked={on} id="flexSwitchCheckDefault" onChange={()=> {
          setOn(!on);
          onToggle(!on);
        }} />
      </div>
    </div>
  )
}

export default ToggleButton