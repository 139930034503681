import React, { useEffect, useState } from 'react';
import styles from './TableTabHeader.module.css';
import Select from 'react-select';
import { AddIcon } from '../../siteicons';

export const TableTabHeader = (props) => {
	const { courseTab, searchPlaceholder, activeTab, onTabClick, setSelectedFilter, selectedFilter, type, orders, setIsOpen } = props
	const handleSelectChange = (event) => {
		setSelectedFilter({
			...selectedFilter,
			order: event.target.value
		});
	};

	return (
		<React.Fragment>
			<div className={`${styles.courseTabContainer} d-inline-flex flex-column `}>
				{courseTab?.length > 0 &&
					<div className={`${styles.courseTabRow} d-inline-flex align-items-center justify-content-between px-4`}>
						<div className='d-inline-flex align-items-center gap-4'>
							{courseTab?.map((item, idx) => {
								return (
									<span role="button" className={`${styles.courseTabBox} ${activeTab === item && styles.courseActiveTab}  align-items-center d-inline-flex px-2`} key={idx} onClick={() => onTabClick(item)}>{item}</span>
								)
							})}
						</div>
						{type === "order" &&
							<div className='d-inline-flex'>
								<Select
									className={`${styles.selected}`}
									value={orders.find((o) => o.value === selectedFilter.order)}
									onChange={(event) => {
										setSelectedFilter((prev) => ({
											...prev,
											order: event?.value  // Fix here: Use event?.value instead of n?.value
										}))
									}}
									options={orders}
								/>
							</div>
						}
					</div>
				}
				{type === "category" &&
					<div className='d-inline-flex align-items-end justify-content-end '>
						<span role="button" className={`${styles.categoryButton} align-items-center d-inline-flex`} onClick={() => setIsOpen(true)} >{<AddIcon color="white" />} Job Category</span>
					</div>
				}
				<div className={`col-12 d-inline-flex align-items-center p-4`}>
					<input type="text" className={`${styles.courseSearchInput} col-12 d-inline-flex`} placeholder={searchPlaceholder} onChange={(e) => {
						setSelectedFilter((prev) => ({
							...prev,
							name: e.target.value
						}))
					}} />
				</div>
			</div>
		</React.Fragment>
	);
};