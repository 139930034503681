import React, { useState } from 'react';
import styles from './CategoryList.module.css';
import { Link } from 'react-router-dom';
import { LocationIcon } from '../../siteicons';
import CommonTable from '../CommonTable/CommonTable';
import { calculateTimeDifference, formatDate, formatTime } from '../../utils/DateTimeFormat';
import CustomPagination from '../../common/CustomPagination';
import CreateCategoryModal from '../Modal/CreateCategoryModal/CreateCategoryModal';
import { uploadfile } from '../../services/user.service';
import { createCategory, updateCategory } from '../../services/job.service';
import toast from 'react-hot-toast';

const CategoryList = ({ categoryList, setSelectedFilter, getAllCategoryList,imageUrl }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [categoryData, setCategoryData] = useState(null);
  const [type, setType] = useState()
  const [categoryId,setCategoryId] = useState()

  const editCategory = (status) => {
    setIsOpen(true);
    setCategoryData(status);
    setType(status?.type)
    setCategoryId(status?.status?.id)
  }

  

  const createJobCategory = async (formData) => {
    const imageFromData = new FormData();
    imageFromData.append("file", formData?.file);
    const response = await uploadfile(imageFromData);
    if (response?.status) {
      const data = {
        name: formData?.name,
        parent_id: formData?.parentId,
        uploaded_file_id: response?.data?.data?.id,
      };

      const result = await createCategory(data);

      if (result.status) {
        getAllCategoryList()
        setIsOpen(false)
        toast.success(result?.msg)
      } else {
        toast.error(result?.error);
      }
    }
  }

  const editJobCategory = async (formData) => {
    if(typeof formData?.file === 'object'){
      const imageFromData = new FormData();
      imageFromData.append("file", formData?.file);
      const response = await uploadfile(imageFromData);
      if (response?.status) {
        const data = {
          name: formData?.name,
          parent_id: formData?.parentId,
          uploaded_file_id: response?.data?.data?.id,
        };
  
        const result = await updateCategory(data, categoryId);
  
        if (result.status) {
          getAllCategoryList()
          setIsOpen(false)
          toast.success(result?.msg)
        } else {
          toast.error(result?.error);
        }
      }
    }else{
      const data = {
        name: formData?.name,
        parent_id: formData?.parentId,
        uploaded_file_id: formData?.file,
      };
      const result = await updateCategory(data, categoryId);
  
      if (result.status) {
        getAllCategoryList()
        setIsOpen(false)
        toast.success(result?.msg)
      } else {
        toast.error(result?.error);
      }
    }
  }

  const columns = [
    {
      name: 'Category Name',
      id: 'category_name',
      sortable: false,
      span: 2,
      renderer: (category) => (
        <div className={`col-12 d-inline-flex align-items-center col-12 gap-3`}>
          <span className={`${styles.categoryImag} flex-shrink-0 d-inline-flex align-items-center text-uppercase justify-content-center`}>{category?.name?.trim()?.charAt(0)}</span>
          <div className='flex-grow-1 d-flex flex-column'>
            <h6 className={`${styles.categoryName} col-12 text-truncate`} title={category?.name}>{category?.name} </h6>
            <span className={`${styles.categoryTiming} d-inline-flex col-12`}>{formatDate(category?.createdAt) + " " + formatTime(category?.createdAt)}</span>
            <span className={`${styles.categoryTimeDifference} d-inline-flex col-12`}>{calculateTimeDifference(category?.createdAt)}</span>
          </div>
        </div>
      )
    },
    {
      name: 'Image',
      id: 'image',
      renderer: (category) => (
        <div className='col-12 d-inline-flex align-items-center gap-2'>
          {category?.uploaded_file_id ?
            <span className={`${styles.categoryImag} flex-shrink-0 d-inline-flex align-items-center text-uppercase justify-content-center`}>
              <img
                className={`${styles.img}`}
                src={imageUrl + category?.cat_picture_data?.file_name}
              />
            </span>
            :
            <b className=''>-</b>
          }
        </div>
      )
    },
    {
      name: 'Parent/Category',
      id: 'Learners',
      renderer: (parent) => (
        <span className={`${styles.tableData}`}> {parent?.parent_detail?.name ?? <b>-</b>}</span>
      )
    },
    {
      name: 'Status',
      id: 'Status',
      renderer: (status) => (
        <span className={`${styles.tableData}`}>
          <span className={`${styles.categoryStatus} ${status?.status === "Pending" ? styles.categoryPending : status?.status === "active" ? styles.categoryLive : status?.status_name === "Rejected" ? styles.categoryRejected : ""} d-inline-flex`}></span> {status?.status}
        </span>
      )
    },
    {
      name: 'Action',
      id: 'action',
      renderer: (status) => (
        <div className='col-12 d-inline-flex align-items-center gap-2'>
          <React.Fragment>
            <span role='button' className={`${styles.acceptBtn} d-inline-flex align-items-center justify-content-center px-3`} onClick={() => editCategory({ status, type: "create" })}
            >Add sub category</span>
            <span role='button' className={`${styles.editBtn} d-inline-flex align-items-center justify-content-center px-3`} onClick={() => editCategory({ status, type: "edit" })} >Edit</span>
          </React.Fragment>
        </div>
      )
    }
  ];

  return (
    <React.Fragment>
      <div className={`${styles.categoryListContainer} d-inline-flex flex-column overflow-hidden`}>
        <CommonTable cols={columns} rows={categoryList?.data} />
        <div className='px-4'>
          <CustomPagination
            totalCount={categoryList?.rows}
            onChange={(n) => {
              setSelectedFilter((prev) => ({
                ...prev,
                pageNumber: n
              }))
            }}
          // activePage={1}
          />
        </div>
        {isOpen === true &&
          <CreateCategoryModal
            setIsOpen={setIsOpen}
            categoryData={categoryData}
            specificCat={categoryList?.data}
            createJobCategory={createJobCategory}
            editJobCategory={editJobCategory}
            type={type}
            imageUrl={imageUrl}
          />
        }
      </div>
    </React.Fragment>
  )
}

export default CategoryList