import React from 'react';
import styles from './RecentPayments.module.css';
import CommonTable from '../CommonTable/CommonTable';
import { Link } from 'react-router-dom';
import { formatDate, formatTime } from '../../utils/DateTimeFormat';

export const RecentPayments = ({ bIllingEmployerDetailList }) => {
   

    const columns = [
        {
            name: 'ID',
            id: 'id',
            renderer: (order) => (
                <Link to={`/order-summary/${order?.order_id}`} className={`${styles.orderLink} d-inline-flex text-decoration-none`} role="button">{order?.order_id}</Link>
            )
        },
        {
            name: 'Amount',
            id: 'Amount',
            renderer: (total) => (
                <span className={`${styles.tableData}`}>${total?.amount}</span>
            )
        },
        {
            name: 'Date',
            id: 'Date',
            renderer: ({ date }) => (
                <span className={`${styles.tableData}`}>{formatDate(date) + " " + formatTime(date)}</span>
            )
        },
        {
            name: 'Status',
            id: 'Status',
            renderer: (payment) => (
                <div className='col-12 d-inline-flex align-items-center gap-2'>
                    {payment?.paymentStatus === '1' ? (
                        <span className={`${styles.statusFailed} d-inline-flex align-items-center`}>{payment?.paymentStatusName}</span>
                    ) : payment?.paymentStatus === '3' ? (
                        <span className={`${styles.statusRefunded} d-inline-flex align-items-center`}>{payment?.paymentStatusName}</span>
                    ) : payment?.paymentStatus === '0' ? (
                        <span className={`${styles.statusPending} d-inline-flex align-items-center`}>{payment?.paymentStatusName}</span>
                    ) : payment?.paymentStatus === '2' ? (
                        <span className={`${styles.statusPaid} d-inline-flex align-items-center`}>{payment?.paymentStatusName}</span>
                    ) : ''}
                </div>
            )
        },
        {
            name: 'Actions',
            id: 'Actions',
            renderer: (order) => (
                <Link to={`/order-summary/${order?.order_id}`} className={`${styles.orderLink} d-inline-flex text-decoration-none`} role="button">View Details</Link>
            )
        }
    ];

    return (
        <React.Fragment>
            <div className={`${styles.courseListContainer} d-inline-flex flex-column overflow-hidden`}>
                <h4 className={`${styles.cardTitle} col-12 d-inline-flex p-4 mb-0`}>Recent Payments</h4>
                <CommonTable cols={columns} rows={bIllingEmployerDetailList?.data} />
            </div>
        </React.Fragment>
    )
}