import React, { useState } from 'react';
import styles from './CommonTable.module.css';

const CommonTable = (props) => {
    const { cols, rows } = props;

    function getContentAlignment(alignment) {
        switch (alignment) {
            case 'left':
                return 'start';
            case 'right':
                return 'end';
            default:
                return alignment;
        }
    }


    return (
        <React.Fragment>
            <div className={styles.tableHeader}>
                {cols?.map((col, i) => (
                    <div key={i} className={`${styles.tableHead} ${col?.align !== undefined ? 'text-' + col.align + ' justify-content-' + col.align : ''} ${styles['flex-' + (col.span || 1)]}`}>
                        <h3>{col.name}</h3>
                    </div>
                ))}
            </div>
            <div className={styles.tableBody}>
                {rows?.length > 0 ? rows?.map((row, i) => {
                    return (
                        <div key={i} className={styles.tableRow}>
                            {cols?.map((col, j) => {
                                return (
                                    <div className={`${styles['flex-' + (col.span || 1)]} d-flex ${col?.align !== undefined ? 'text-' + col.align + ' justify-content-' + getContentAlignment(col.align) : ''}`} key={i.toString() + j + row.id}>
                                        {col.renderer ? col.renderer(row) || '-' : row[col.id]}
                                    </div>
                                );
                            })}
                        </div>
                    );
                }) : (
                    <div className={styles.tableRowNoData}>
                        <p>No data available</p>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default CommonTable;
