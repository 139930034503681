import React, { useEffect, useState } from 'react';
import styles from './Employer.module.css';
import { Layout } from '../../Components/Layout/Layout';
import { PageHeader } from '../../Components/PageHeader/PageHeader';
import { SalesIcon, TrendIcon } from '../../siteicons';
import { TableTabHeader } from '../../Components/TableTabHeader/TableTabHeader';
import { EmployerList } from '../../Components/EmployerList/EmployerList';
import { getBIllingEmployer } from '../../services/billing.service';
import { formatDate } from '../../utils/DateTimeFormat';
import { constantvariables } from '../../common/constant';

export const Employer = () => {
    const [bIllingEmployerList, setBIllingEmployerList] = useState([])
    const [standardSubscription, setStandardSubscription] = useState(0)
    const [standardSubscriptionNumber, setStandardSubscriptionNumber] = useState(0)
    const [premiumSubscription, setpremiumSubscription] = useState(0)
    const [premiumSubscriptionNumber, setpremiumSubscriptionNumber] = useState(0)
    const [selectedFilter, setSelectedFilter] = useState({
        pageNumber: 1,
        name: "",
        start_date: '',
        end_date: '',
        // start_date: formatDate(new Date()),
        // end_date: formatDate(new Date()),
    })
    
    const getAllBIllingEmployerList = async () => {
        let filters = {
            page_no: selectedFilter?.pageNumber,
            user_name: selectedFilter?.name,
        }
        if(selectedFilter?.start_date !== '') {
            filters['start_date'] = selectedFilter?.start_date
        }
        if(selectedFilter?.end_date !== '') {
            filters['end_date'] = selectedFilter?.end_date
        }

        // if (activeTab !== "All") {
        //     filters.approved_by_admin = activeTab;
        // }
        const result = await getBIllingEmployer(filters);
        if (result.status) {
            let subscriptionPlanSales = (result.data?.getPerPlanSubscriptionSaleData) ? result.data.getPerPlanSubscriptionSaleData : [];
            let planLength = subscriptionPlanSales.length;
            for(let start = 0; start < planLength; start++) {
                const planType = subscriptionPlanSales[start].plan_type;
                const planSaleValues = subscriptionPlanSales[start].total_amount;
                const numberOfSales = subscriptionPlanSales[start].number_of_sales;
                (planType === constantvariables.premium) ? setpremiumSubscription(planSaleValues) : setStandardSubscription(planSaleValues);
                (planType === constantvariables.premium) ? setpremiumSubscriptionNumber(numberOfSales) : setStandardSubscriptionNumber(numberOfSales);
            }
            setBIllingEmployerList(result);
        }
    };

    useEffect(() => {
        getAllBIllingEmployerList();
    }, [selectedFilter])

    return (
        <Layout>
            <div className='col-12 d-inline-flex flex-column p-4'>
                <PageHeader title="Employer" 
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                />
                <div className='col-12 d-inline-flex align-items-stretch gap-4 pt-4'>
                    <div className={`${styles.dashboardBlock} col-4 d-inline-flex flex-column p-3 flex-shrink-1`}>
                        <div className='col-12 d-inline-flex align-items-center justify-content-between mb-3'>
                            <span className={`${styles.blockTitle} d-inline-flex`}>Sales from Standard Subscription</span>
                            <span className={`${styles.blockIconBox} d-inline-flex`}><SalesIcon color="#5D5FEF" /></span>
                        </div>
                        <h2 className={`${styles.dashboardStats} d-inline-flex col-12`}>${standardSubscription}</h2>
                        <div className='col-12 d-inline-flex align-items-center gap-2'>
                            <span className={`${styles.statsStatus} d-inline-flex align-items-center gap-1`}>
                                <TrendIcon color="#139a74" /> {standardSubscriptionNumber}
                            </span>
                            <span className={`${styles.statsTitle} d-inline-flex`}>Number of Sales</span>
                        </div>
                    </div>
                    <div className={`${styles.dashboardBlock} col-4 d-inline-flex flex-column p-3 flex-shrink-1`}>
                        <div className='col-12 d-inline-flex align-items-center justify-content-between mb-3'>
                            <span className={`${styles.blockTitle} d-inline-flex`}>Sales from Premium Subscription</span>
                            <span className={`${styles.blockIconBox} d-inline-flex`}><SalesIcon color="#5D5FEF" /></span>
                        </div>
                        <h2 className={`${styles.dashboardStats} d-inline-flex col-12`}>${premiumSubscription}</h2>
                        <div className='col-12 d-inline-flex align-items-center gap-2'>
                            <span className={`${styles.statsStatus} d-inline-flex align-items-center gap-1`}>
                                <TrendIcon color="#139a74" /> {premiumSubscriptionNumber}
                            </span>
                            <span className={`${styles.statsTitle} d-inline-flex`}>Number of Sales</span>
                        </div>
                    </div>
                </div>
                <div className='col-12 d-inline-flex flex-column mt-4'>
                    <TableTabHeader
                        searchPlaceholder="Search for customer name..."
                        setSelectedFilter={setSelectedFilter}
                        onClick={(item) => {
                            setSelectedFilter((prev) => ({
                                ...prev,
                                pageNumber: 1
                            }))
                        }}
                    />
                    <EmployerList
                        bIllingEmployerList={bIllingEmployerList}
                        setSelectedFilter={setSelectedFilter}
                    />
                </div>
            </div>
        </Layout>
    )
}