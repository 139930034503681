import React from 'react'
import ReactPaginate from 'react-paginate';

const CustomPagination = ({ totalCount, onChange, activePage }) => {
  const pageCount = Math.ceil(totalCount / 10);

  const handlePageClick = (event) => {
    onChange(event.selected + 1);
  };

  return (
    <div>
      <ReactPaginate
        breakLabel="..."
        nextLabel="..."
        onPageChange={handlePageClick}
        forcePage={activePage ? activePage - 1 : undefined}
        pageRangeDisplayed={1}
        pageCount={pageCount}
        previousLabel="Prev"
        pageClassName='card page-item'
        pageLinkClassName='page-link'
        previousClassName='card page-item'
        previousLinkClassName='page-link'
        nextClassName='card page-item'
        nextLinkClassName='page-link'
        breakClassName='page-item'
        breakLinkClassName='page-link'
        containerClassName='pagination w-100'
        renderOnZeroPageCount={null}
      />
    </div>
  )
}

export default CustomPagination