import React, { useState } from 'react'
import toast from 'react-hot-toast';
import styles from './changeStatus.module.css'
import { updateCourses } from '../../../services/courses.service';

const ChangeStatusModal = ({ setIsOpen, listData, title, handleApprove }) => {
  const [reason, setReason] = useState("")

  return (
    <React.Fragment>
      <div class="modal-backdrop fade show"></div>
      <div className="modal d-inline-block" id="exampleModal" role="dialog" ariaHidden="false">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{`Change status`}</h5>
              <button type="button" onClick={() => setIsOpen(false)} className={`${styles.closes} close`} data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body m-2">
              {title}
            </div>
            {listData?.approved_by_admin_name === "Approved" &&
              <div className="modal-body m-2">
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon3">Reason</span>
                  <input type="text" class="form-control" id="basic-url" aria-describedby="basic-addon3" onChange={(e)=> setReason(e.target.value)} />
                </div>
              </div>
            }
            <div className="modal-footer">
              {listData?.approved_by_admin_name === "Approved" ?
                <span role='button' className={`${styles.rejectBtn} d-inline-flex align-items-center justify-content-center px-3`} onClick={() => {
                  handleApprove({ status: listData, id: 0, reason: reason })
                }} >Reject</span>
                :
                <span role='button' className={`${styles.acceptBtn} d-inline-flex align-items-center justify-content-center px-3`} onClick={() => { handleApprove({ status: listData, id: 1 }) }} >Approve</span>
              }
              <span role='button' className={`${styles.close} d-inline-flex align-items-center justify-content-center px-3`} onClick={() => setIsOpen(false)} >Close</span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ChangeStatusModal