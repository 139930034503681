import React, { useEffect, useState } from 'react';
import styles from './AlertForm.module.css';
import { createAlert } from '../../services/alert.service';
import toast from 'react-hot-toast';

export const AlertForm = ({ alertConstDateType, getAllAlertList }) => {
    const [message, setMessage] = useState('');
    const [selectedPage, setSelectedPage] = useState("");

    const handleInputChange = (e) => {
        setMessage(e.target.value);
    }
    const handleSelectChange = (event) => {
        setSelectedPage(event.target.value);
    };


    const handlePublish = async () => {
        const data = {
            message: message,
            pages: [selectedPage],
            status: "2"
        }
        const result = await createAlert(data);

        if (result.status) {
            setMessage("")
            setSelectedPage("")
            getAllAlertList()
            toast.success(result?.msg)
        } else {
            toast.error(result?.error);
        }
    }

    const hamdleSaveDraft = async () => {
        const data = {
            message: message,
            pages: [selectedPage],
            status: "0"
        }
        const result = await createAlert(data);

        if (result.status) {
            setMessage("")
            setSelectedPage("")
            getAllAlertList()
            toast.success(result?.msg)
        } else {
            toast.error(result?.error);
        }
    }

    useEffect(() => {
        if (alertConstDateType) {
            setSelectedPage(alertConstDateType.pages[0]?.value)
        }
    }, [alertConstDateType])

    return (
        <React.Fragment>
            <div className='col-12 d-inline-flex flex-column mt-4'>
                <h2 className={`${styles.fromTitle} col-12 d-inline-flex mb-3`}>These are alerts that will pop-up in a banner at the top of the Website.</h2>
                <div className={`${styles.dashboardBlock} col-12 d-inline-flex flex-column`}>
                    <h4 className={`${styles.cardTitle} col-12 d-inline-flex p-4`}>Create Alert</h4>
                    <div className='col-12 d-inline-flex p-4 flex-column gap-4'>
                        <div className='col-12 d-inline-flex flex-column gap-2'>
                            <label className={`${styles.inputLabel} col-12 d-inline-start mb-0`}>Message</label>
                            <textarea placeholder='Write Message...' className={`${styles.formTextarea} col-12 d-inline-flex p-3`} value={message} onChange={handleInputChange} ></textarea>
                        </div>
                        <div className='col-12 d-inline-flex flex-column gap-2'>
                            <label className={`${styles.inputLabel} col-12 d-inline-start mb-0`}>Page</label>
                            <select className={`${styles.selectBox} col-12 d-inline-flex`} onChange={handleSelectChange} value={selectedPage}>
                                {alertConstDateType?.pages?.map((item, index) => (
                                    <option key={index} value={item?.value}>{item?.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className='col-12 d-inline-flex gap-3 mt-5'>
                            <span role="button" className={`${styles.publishBtn} d-inline-flex align-items-center`} onClick={handlePublish} >Publish</span>
                            <span role="button" className={`${styles.saveDraft} d-inline-flex align-items-center`} onClick={hamdleSaveDraft} >Save as Draft</span>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}