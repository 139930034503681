import React, { useEffect, useState } from 'react';
import styles from './CourseList.module.css';
import CommonTable from '../CommonTable/CommonTable';
import { calculateTimeDifference, formatDate, formatDateTime, formatTime } from '../../utils/DateTimeFormat';
import { updateCourses } from '../../services/courses.service';
import toast from 'react-hot-toast';
import CustomPagination from '../../common/CustomPagination';
import ChangeStatusModal from '../Modal/ChangeStatusModal/changeStatusModal';
import ViewDetailsModal from '../Modal/ViewDetailsModal/viewDetailsModal';
import ToggleButton from '../../common/ToggleButton/ToggleButton';

export const CourseList = ({ coursesList, getAllCoursesList, setActiveTab, setSelectedFilter }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [openCourse, setOpenCourse] = useState(null);

    const handleApprove = async ({ status, id, reason, efficient, specialized, homePage }) => {
        const data = {
            course_id: status?.id,
            approved_by_admin: id,
            admin_reject_reason: reason,
            show_as_efficient: efficient,
            specialized_by_admin: specialized,
            show_on_home_page: homePage
        };

        const result = await updateCourses(data);

        if (result.status) {
            setIsOpen(false)
            getAllCoursesList();
            setActiveTab("All")
            toast.success(result?.msg)
        } else {
            toast.error(result?.error);
        }
    }


    const changeCourseStatus = (data) => {
        setIsOpen(true);
        setOpenCourse(data);
    }

    const viewDetailsModal = (data) => {
        setOpenViewModal(true)
        setOpenCourse(data);
    }

    const columns = [
        {
            name: 'Courses',
            id: 'courses',
            sortable: false,
            span: 2,
            renderer: (course) => (
                <div className={`col-12 d-inline-flex align-items-center col-12 gap-3`}>
                    <span className={`${styles.courseImag} flex-shrink-0 d-inline-flex align-items-center text-uppercase justify-content-center`}>{course?.name?.trim()?.charAt(0)}</span>
                    <div className='flex-grow-1 d-flex flex-column'>
                        <h6 className={`${styles.courseName} col-12 text-truncate`} title={course?.name}>{course?.name} </h6>
                        <span className={`${styles.courseTiming} d-inline-flex col-12`}>{formatDate(course?.createdAt) + " " + formatTime(course?.createdAt)}</span>
                        <span className={`${styles.courseTimeDifference} d-inline-flex col-12`}>{calculateTimeDifference(course?.createdAt)}</span>
                    </div>
                </div>
            )
        },
        {
            name: 'Educator',
            id: 'educator',
            renderer: (employer) => (
                <div className='col-12 d-inline-flex align-items-center gap-2'>
                    <span className={`${styles.authourImg} flex-shrink-0 d-inline-flex align-items-center justify-content-center`}>{employer?.user_name?.charAt(0)}</span>
                    <span className={`${styles.authorName} d-inline-flex`}>{employer?.user_name}</span>
                </div>
            )
        },
        {
            name: 'Learners',
            id: 'Learners',
            renderer: (learners) => (
                <span className={`${styles.tableData}`}> {learners?.course_learners ?? "-"}</span>
            )
        },
        {
            name: 'Efficient',
            id: 'Show',
            renderer: (show) => (
                <span className={`${styles.tableData}`}>
                    <ToggleButton
                        onToggle={(value) => handleApprove({
                            status: show,
                            id: show?.approved_by_admin,
                            reason: "",
                            efficient: Number(value)
                        })}
                        defaultValue={show?.show_as_efficient}
                    />
                </span>
            )
        },
        {
            name: 'For Homepage',
            id: 'Show',
            renderer: (Show) => (
                <span className={`${styles.tableData}`}>
                    <span className={`${styles.tableData}`}>
                        <ToggleButton
                            onToggle={(value) => handleApprove({
                                status: Show,
                                id: Show?.approved_by_admin,
                                reason: "",
                                homePage: Number(value)
                            })}
                            defaultValue={Show?.show_on_home_page}
                        />
                    </span>
                </span>
            )
        },
        {
            name: 'Specialized',
            id: 'Status',
            renderer: (Show) => (
                <span className={`${styles.tableData}`}>
                    <ToggleButton
                        onToggle={(value) => handleApprove({
                            status: Show,
                            id: Show?.approved_by_admin,
                            reason: "",
                            specialized: Number(value)
                        })}
                        defaultValue={Show?.specialized_by_admin}
                    />
                </span>
            )
        },
        {
            name: 'Status',
            id: 'Status',
            renderer: (status) => (
                <span className={`${styles.tableData}`}>
                    <span className={`${styles.courseStatus} ${status?.status_name === "Pending" ? styles.coursePending : status?.status_name === "Active" ? styles.courseLive : status?.status_name === "Rejected" ? styles.courseRejected : ""} d-inline-flex`}></span> {status?.status_name}
                </span>
            )
        },
        {
            name: 'Action',
            id: 'action',
            renderer: (status) => (
                <div className='col-12 d-inline-flex align-items-center gap-2'>
                    {status?.status_name === "Pending" ? (
                        <React.Fragment>
                            <span role='button' className={`${styles.acceptBtn} d-inline-flex align-items-center justify-content-center px-3`} onClick={() => { handleApprove({ status, id: 1 }) }} >Approve</span>
                            <span role='button' className={`${styles.rejectBtn} d-inline-flex align-items-center justify-content-center px-3`} onClick={() => { handleApprove({ status, id: 0 }) }} >Reject</span>
                        </React.Fragment>
                    ) : status?.status_name === "Active" ? (
                        <React.Fragment>
                            <span role='button' className={`${styles.changeStatusBtn} d-inline-flex align-items-center justify-content-center px-1`}
                                onClick={() => changeCourseStatus(status)}>Change Status</span>
                            <span role='button' className={`${styles.rejectBtn} d-inline-flex align-items-center justify-content-center px-2`} onClick={() => viewDetailsModal(status)} >View</span>
                        </React.Fragment>
                    ) : status?.status_name === "Rejected" ? (
                        <React.Fragment>
                            <span role='button' className={`${styles.changeStatusBtn} d-inline-flex align-items-center justify-content-center px-0.9`}
                                onClick={() => changeCourseStatus(status)}>Change Status</span>
                            <span className={`${styles.rejectBtn} d-inline-flex align-items-center justify-content-center px-1`} onClick={() => viewDetailsModal(status)} >View</span>
                        </React.Fragment>
                    ) : ''}
                </div>
            )
        }
    ];


    return (
        <React.Fragment>
            <div className={`${styles.courseListContainer} d-inline-flex flex-column overflow-hidden`}>
                <CommonTable cols={columns} rows={coursesList?.data} />
                <div className='px-4'>
                    <CustomPagination
                        totalCount={coursesList?.rows}
                        onChange={(n) => {
                            setSelectedFilter((prev) => ({
                                ...prev,
                                pageNumber: n
                            }))
                        }}
                    // activePage={1}
                    />
                </div>
                {isOpen === true &&
                    <ChangeStatusModal
                        setIsOpen={setIsOpen}
                        listData={openCourse}
                        setActiveTab={setActiveTab}
                        title={`Do you want to status of this course ${openCourse?.name}`}
                        handleApprove={handleApprove}
                    />
                }

                {openViewModal === true &&
                    <ViewDetailsModal
                        setIsOpen={setOpenViewModal}
                        listData={openCourse}
                    />
                }
            </div>
        </React.Fragment>
    )
}