import React, { useEffect, useState } from 'react';
import styles from './SideBar.module.css';
import siteLogo from '../../assets/images/Aus_Getters_Logo_rectangle.svg';
import { AlertIcon, BillingIcon, CategoryIcon, CourseIcon, DashboardIcon, DownArrowIcon, ExclusiveServices, JobsIcon, UsersIcon } from '../../siteicons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useApp } from '../../context/AppContextProvider';
import { useAuth } from '../../hook/useAuth';

export const SideBar = ({ nav }) => {
	const { auth, setAuth } = useAuth();
	const [sideNavArr, setSideNavArr] = useState([]);
	const [activeNavIdx, setActiveNavIdx] = useState(auth?.activeNavId);
	const navigate = useNavigate();
	const location = useLocation();

	const asideNav = [
		{
			name: 'Dashboard',
			icon: <DashboardIcon />,
			subMenu: [
				{
					name: 'Overview',
					link: '/overview'
				},
				{
					name: 'Analytics',
					link: '/analytics'
				}
			]
		},
		{
			name: 'Category',
			link: '/category',
			icon: <CategoryIcon />
		},
		{
			name: 'Exclusive Service',
			link: '/exclusive-service',
			icon: <ExclusiveServices />
		},
		{
			name: 'Courses',
			link: '/courses',
			icon: <CourseIcon />
		},
		{
			name: 'Jobs',
			link: '/jobs',
			icon: <JobsIcon />
		},
		{
			name: 'Billing',
			icon: <BillingIcon />,
			subMenu: [
				{
					name: 'All Purchasers',
					link: '/billing-all-purchasers'
				},
				{
					name: 'Employer',
					link: '/billing-employer'
				},
				{
					name: 'Course Sales',
					link: '/billing-courses'
				}
			]
		},
		{
			name: 'User',
			link: '/user',
			icon: <UsersIcon />
		},
		{
			name: 'Alert',
			link: '/alert',
			icon: <AlertIcon />
		}
	];

	const openSubNav = (idx, link) => {
		let subNavArr = sideNavArr;
		if (subNavArr.includes(idx)) {
			setSideNavArr([]);
			localStorage.removeItem("activeNavId");
			setAuth({ ...auth, activeNavId: [] });
		} else {
			setSideNavArr([idx]);
			localStorage.setItem("activeNavId", JSON.stringify(idx));
			setAuth({ ...auth, activeNavId: [idx] });
		}
		if (link !== null && link !== undefined) {
			navigate(link);
		}
	}

	useEffect(() => {
		setActiveNavIdx(auth?.activeNavId)
	}, [auth?.activeNavId]);

	return (
		<React.Fragment>
			<div className={`${styles.sideBarRow} col-12 d-inline-flex flex-column h-100 gap-3 overflow-y-auto`}>
				<div className={`${styles.topLogoBox} col-12 d-inline-flex p-3`}>
					<img src={siteLogo} alt="Site Logo" className='object-fit-contain d-inline-block' />
				</div>
				{asideNav?.length > 0 &&
					<ul className='col-12 d-inline-flex flex-column list-unstyled gap-1 px-2'>
						{asideNav?.map((item, index) => {
							return (
								<li className={`${styles.sideNavListRow} col-12 d-inline-flex flex-column`} key={index}>
									<div role="button" to={item.link} className={`${styles.sideNavList} ${sideNavArr.includes(index) || (activeNavIdx !== '' && activeNavIdx?.includes(index)) ? styles.sideNavListOpen : null} d-inline-flex text-decoration-none col-12 justify-content-between align-items-center ${location?.pathname === item?.link && styles.activeNavList}`} onClick={() => openSubNav(index, item.link)}>
										<div className='d-inline-flex align-items-center gap-4'>
											<span className={`${styles.sideNavIcon} d-inline-flex`}>{item.icon}</span>
											<span className={`${styles.navItemName} d-inline-flex`}>{item.name}</span>
										</div>
										{item.subMenu?.length > 0 &&
											<span className={`${styles.navArrowIcon} d-inline-flex`}><DownArrowIcon /></span>
										}
									</div>
									{item.subMenu?.length > 0 &&
										<ul className={`${sideNavArr.includes(index) || (activeNavIdx !== '' && activeNavIdx?.includes(index)) ? styles.openNavList : styles.closeNavList} col-12 flex-column list-unstyled d-inline-flex flex-column gap-1 overflow-hidden mt-1`}>
											{item?.subMenu.map((subItem, subIdx) => {
												return (
													<li className={`${styles.sideSubNavList} ${location?.pathname === subItem?.link && styles.activeNavList} col-12 d-inline-flex`} key={subIdx}>
														<Link to={subItem.link} className={`d-inline-flex align-items-center text-decoration-none col-12`}>
															<span className={`${styles.navItemName} d-inline-flex`}>{subItem.name}</span>
														</Link>
													</li>
												);
											})}
										</ul>
									}
								</li>
							);
						})}
					</ul>
				}
			</div>
		</React.Fragment>
	);
};