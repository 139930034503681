import React, { useEffect, useState } from 'react';
import { Layout } from '../../Components/Layout/Layout';
import { PageHeader } from '../../Components/PageHeader/PageHeader';
import { AlertForm } from '../../Components/AlertForm/AlertForm';
import { AlertInfo } from '../../Components/AlertInfo/AlertInfo';
import AlertList from '../../Components/AlertList/AlertList';
import { getAlertConstData, getAlertDetails, getAlertList, updateAlertDetails } from '../../services/alert.service';
import { TableTabHeader } from '../../Components/TableTabHeader/TableTabHeader';
import toast from 'react-hot-toast';

export const Alert = () => {
    const alertTab = ['All', 'Published', 'Unpublished', "Saved as draft"];
    const [alertList, setAlertList] = useState([]);
    const [alertConstDateType, setAlertConstDateType] = useState();
    const [activeTab, setActiveTab] = useState("All");
    const [selectedFilter, setSelectedFilter] = useState({
        pageNumber: 1,
        date_range: "show_all",
        name: "",
    });
    const [alertDetail, setAlertDetail] = useState();
    const status = alertConstDateType?.alertStatusEnums.find(item => item?.label == activeTab)

    // for getting the Alert Const Data
    const getAlertConst = async () => {
        const result = await getAlertConstData();
        if (result.status) {
            setAlertConstDateType(result?.data);
        } else {
            toast.error(result?.error);
        }
    }

    // for getting the all alert list data
    const getAllAlertList = async (data) => {
        const filters = {
            page_no: selectedFilter?.pageNumber,
        }

        if (activeTab !== "All") {
            filters.status = status ? status?.value : "";
        }
        const result = await getAlertList(filters);

        if (result.status) {
            setAlertList(result);
        }
    };

    // for the getting the particular alert details
    const getAlertDetail = async (data) => {
        const id = data?.id;
        const result = await getAlertDetails(id);
        if (result.status) {
            setAlertDetail(result?.data);
        }
    }

    const updateAlert = async (data, id) => {        
        const result = await updateAlertDetails(id, data);

        if (result.status) {
            getAlertDetail(data)
            getAllAlertList()
            toast.success(result?.msg)
        } else {
            toast.error(result?.error);
        }
    }


    useEffect(() => {
        getAllAlertList();
        getAlertConst()
    }, [activeTab, selectedFilter]);

    useEffect(() => {
        if (alertDetail === undefined && alertList?.data && alertList.data.length > 0) {
            setAlertDetail(alertList.data[0]);
        }
    }, [alertDetail, alertList]);

    return (
        <Layout>
            <div className='col-12 d-inline-flex flex-column p-4'>
                <PageHeader title="Alert" />
                <div className='col-12 d-inline-flex align-items-start gap-4'>
                    <div className='col-8 flex-shrink-1'>
                        <AlertForm
                            alertConstDateType={alertConstDateType}
                            getAllAlertList={getAllAlertList}
                        />
                        <div className='col-24 mt-5'>
                            <TableTabHeader
                                courseTab={alertTab}
                                // searchPlaceholder="Search Courses"
                                activeTab={activeTab}
                                setSelectedFilter={setSelectedFilter}
                                onTabClick={(item) => {
                                    setActiveTab(item);
                                    setSelectedFilter((prev) => ({
                                        ...prev,
                                        pageNumber: 1
                                    }))
                                }}
                            />
                            <AlertList
                                alertList={alertList}
                                getAllAlertList={getAllAlertList}
                                setSelectedFilter={setSelectedFilter}
                                getAlertDetail={getAlertDetail}
                            />
                        </div>
                    </div>
                    <div className='col-4 flex-shrink-1'>
                        <AlertInfo
                            alertDetail={alertDetail}
                            updateAlert={updateAlert}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}