import React, { useEffect, useState } from 'react'
import { Layout } from '../../Components/Layout/Layout'
import { PageHeader } from '../../Components/PageHeader/PageHeader'
import { TableTabHeader } from '../../Components/TableTabHeader/TableTabHeader'
import CategoryList from '../../Components/CategoryList/CategoryList'
import { createCategory, getCategoryList } from '../../services/job.service'
import CreateCategoryModal from '../../Components/Modal/CreateCategoryModal/CreateCategoryModal'
import toast from 'react-hot-toast'
import { uploadfile } from '../../services/user.service'

const Category = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [specificCat, setSpecificCat] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState({
    pageNumber: 1,
    // date_range: "show_all",
    name: "",
  })
  const [imageUrl, setImageUrl] = useState("")


  const createJobCategory = async (formData) => {
    const imageFromData = new FormData();
    imageFromData.append("file", formData?.file);
    const response = await uploadfile(imageFromData);
    if (response?.status) {
      const data = {
        name: formData?.name,
        parent_id: formData?.parentId,
        uploaded_file_id: response?.data?.data?.id,
      };

      const result = await createCategory(data);

      if (result.status) {
        getAllCategoryList()
        setIsOpen(false)
        toast.success(result?.msg)
      } else {
        toast.error(result?.error);
      }
    }
  }

  const getAllCategoryList = async () => {
    const filters = {
      page_no: selectedFilter?.pageNumber,
      // date_range: selectedFilter?.date_range,
      name: selectedFilter?.name,
    }
    const result = await getCategoryList(filters);
    if (result.status) {
      setCategoryList(result?.data?.jobCategories);
      setSpecificCat(result?.data?.specificCat?.data)
      setImageUrl(result?.data?.File_Base_Url)

    }
  }

  useEffect(() => {
    getAllCategoryList()
  }, [selectedFilter])

  return (

    <Layout>
      <div className='col-12 d-inline-flex flex-column p-4'>
        <PageHeader
          title="Category"
          // filterDateType={courseSearchDateType}
          // selectedFilter={selectedFilter}
          // setSelectedFilter={setSelectedFilter}
          type={"filter"}
        />
        <div className='col-12 d-inline-flex flex-column mt-4'>
          <TableTabHeader
            // courseTab={courseTab}
            searchPlaceholder="Search Category"
            // activeTab={activeTab}
            setSelectedFilter={setSelectedFilter}
            // onTabClick={(item) => {
            //   setActiveTab(item);
            //   setSelectedFilter((prev) => ({
            //     ...prev,
            //     pageNumber: 1
            //   }))
            // }}
            type={"category"}
            setIsOpen={setIsOpen}
          />

          <CategoryList
            categoryList={categoryList}
            getAllCategoryList={getAllCategoryList}
            // setActiveTab={setActiveTab}
            setSelectedFilter={setSelectedFilter}
            imageUrl={imageUrl}
          />
        </div>

        {isOpen === true &&
          <CreateCategoryModal
            setIsOpen={setIsOpen}
            specificCat={specificCat}
            createJobCategory={createJobCategory}
            type={"create"}
          />
        }
      </div>
    </Layout>
  )
}

export default Category