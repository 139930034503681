import React, { useState } from 'react';
import styles from './JobList.module.css';
import CommonTable from '../CommonTable/CommonTable';
import { calculateTimeDifference, formatDate, formatTime } from '../../utils/DateTimeFormat';
import CustomPagination from '../../common/CustomPagination';
import { updateJobStatus } from '../../services/job.service';
import toast from 'react-hot-toast';
import ChangeStatusModal from '../Modal/ChangeStatusModal/changeStatusModal';
import JobViewModal from '../Modal/JobViewModal/jobViewModal';

export const JobList = ({ jobList, getJobLIsts, setSelectedFilter, jobStstusData, setActiveTab }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [openJob, setOpenJob] = useState(null);
    const [openJobModal, setOpenJobModal] = useState(false);

    const handleApprove = async ({ status, id }) => {
        const data = {
            job_id: status?.id,
            approved_by_admin: id,
        };
        const result = await updateJobStatus(data);

        if (result.status) {
            setIsOpen(false)
            getJobLIsts();
            setActiveTab("All")
            toast.success(result?.msg)
        } else {
            toast.error(result?.error);
        }
    }

    const changeCourseStatus = (data) => {
        setIsOpen(true);
        setOpenJob(data);

    }

    const viewDetailsModal = (data) => {
        setOpenJobModal(true)
        setOpenJob(data);
    }

    const columns = [
        {
            name: 'Jobs',
            id: 'Jobs',
            sortable: false,
            span: 2,
            renderer: (jobs) => (
                <div className={`col-12 d-inline-flex align-items-center col-12 gap-3`}>
                    <span className={`${styles.courseImag} flex-shrink-0 d-inline-flex align-items-center justify-content-center`}>
                        {jobs?.approved_by_admin_name === "Pending" &&
                            <span class={`${styles.customBadge}`}>New</span>
                        }
                        {jobs?.title?.charAt(0)}</span>
                    <div className='flex-grow-1 d-flex flex-column'>
                        <h6 className={`${styles.courseName} col-12 text-truncate`} title={jobs?.title} >{jobs?.title}</h6>
                        <span className={`${styles.courseTiming} d-inline-flex col-12`}>{formatDate(jobs?.createdAt) + " " + formatTime(jobs?.createdAt)}</span>
                        <span className={`${styles.courseTimeDifference} d-inline-flex col-12`}>{calculateTimeDifference(jobs?.createdAt)}</span>
                    </div>
                </div>
            )
        },
        {
            name: 'Employer',
            id: 'Employer',
            renderer: (employer) => (
                <div className='col-12 d-inline-flex align-items-center gap-2'>
                    <span className={`${styles.authourImg} flex-shrink-0 d-inline-flex align-items-center justify-content-center`}>{employer?.user_name?.charAt(0)}</span>
                    <span className={`${styles.authorName} d-inline-flex`} >{employer?.user_name}</span>
                </div>
            )
        },
        {
            name: 'Work Location',
            id: 'work_location',
            renderer: (type) => (
                <div className='col-12 d-inline-flex align-items-center gap-2'>
                    <span className={`${styles.authorName} d-inline-flex`}>{type?.work_location === "in_home" ? 'In Home' : 'Person'}</span>
                </div>
            )
        },
        {
            name: 'Admin Status ',
            id: 'Status',
            renderer: (status) => (
                <span className={`${styles.tableData}`}>
                    <span className={`${styles.courseStatus} ${status?.approved_by_admin_name === "Pending" ? styles.coursePending : status?.approved_by_admin_name === "Approved" ? styles.courseLive : status?.status_name === "Rejected" ? styles.courseExpired : ''} d-inline-flex`}></span>
                    {status?.approved_by_admin_name}
                </span >
            )
        },
        {
            name: 'Job Status',
            id: 'Status',
            renderer: (status) => (
                <span className={`${styles.tableData}`}>
                    <span className={`${styles.courseStatus} ${status?.status_name === "Pending" ? styles.coursePending : status?.status_name === "Inactive" ? styles.coursePending : status?.status_name === "Active" ? styles.courseLive : status?.status_name === "Expired" ? styles.courseExpired : status?.status_name === "Cancelled" ? styles.courseExpired : ''} d-inline-flex`}></span>
                    {status?.status_name}
                </span>
            )
        },
        {
            name: 'Expired Date',
            id: 'expire_date',
            renderer: (type) => (
                <div className='col-12 d-inline-flex align-items-center gap-2'>
                    <span className={`${styles.authorName} d-inline-flex`}>{formatDate(type?.expire_date) + " " + formatTime(type?.expire_date)}</span>
                </div>
            )
        },
        {
            name: 'Action',
            id: 'action',
            renderer: (status) => (
                <div className='col-12 d-inline-flex align-items-center gap-2'>
                    {status?.approved_by_admin_name === "Pending" ? (
                        <React.Fragment>
                            <span role='button' className={`${styles.acceptBtn} d-inline-flex align-items-center justify-content-center px-3`} onClick={() => { handleApprove({ status, id: 1 }) }} >Approve</span>
                            <span role='button' className={`${styles.rejectBtn} d-inline-flex align-items-center justify-content-center px-3`} onClick={() => { handleApprove({ status, id: 0 }) }} >Reject</span>
                        </React.Fragment>
                    )
                        : status?.approved_by_admin_name === "Approved" ? (
                            <React.Fragment>
                                <span role='button' className={`${styles.changeStatusBtn} d-inline-flex align-items-center justify-content-center px-1`} onClick={() => changeCourseStatus(status)} >Change Status</span>
                                <span role='button' className={`${styles.rejectBtn} d-inline-flex align-items-center justify-content-center px-2`} onClick={() => viewDetailsModal(status)} >View</span>
                            </React.Fragment>
                        )
                            : status?.approved_by_admin_name === "Rejected" ? (
                                <React.Fragment>
                                    <span role='button' className={`${styles.changeStatusBtn} d-inline-flex align-items-center justify-content-center px-1`} onClick={() => changeCourseStatus(status)} >Change Status</span>
                                    <span role='button' className={`${styles.rejectBtn} d-inline-flex align-items-center justify-content-center px-2`}>View</span>
                                </React.Fragment>
                            )
                                : <React.Fragment>
                                    <span role='button' className={`${styles.rejectBtn} d-inline-flex align-items-center justify-content-center px-3`}>View</span>
                                </React.Fragment>
                    }
                </div>
            )
        },

    ];

    return (
        <React.Fragment>
            <div className={`${styles.courseListContainer} d-inline-flex flex-column overflow-hidden`}>
                <CommonTable cols={columns} rows={jobList?.data} />
                <div className='px-4'>
                    {jobList?.rows ?
                        <CustomPagination
                            totalCount={jobList?.rows}
                            onChange={(n) => {
                                setSelectedFilter((prev) => ({
                                    ...prev,
                                    pageNumber: n
                                }))
                            }}
                            activePage={1}
                        /> :
                        ""
                    }
                </div>
                {isOpen === true &&
                    <ChangeStatusModal
                        setIsOpen={setIsOpen}
                        listData={openJob}
                        title={`Do you want to status of this jon ${openJob?.title}`}
                        handleApprove={handleApprove}
                    />
                }

                {openJobModal === true &&
                    <JobViewModal
                        setIsOpen={setOpenJobModal}
                        listData={openJob}
                    />
                }
            </div>
        </React.Fragment>
    )
}