import React, { useState } from 'react';
import styles from './ExclusiveServiceList.module.css';
import { calculateTimeDifference, formatDate, formatTime } from '../../utils/DateTimeFormat';
import CommonTable from '../CommonTable/CommonTable';
import ExclusiveServiceModal from '../Modal/ExclusiveServiceModal/exclusiveServiceModal';
import CustomPagination from '../../common/CustomPagination';

const ExclusiveServiceList = ({ exclusiveServiceList, setSelectedFilter }) => {
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openExclusiveService, setOpenExclusiveService] = useState(null);

  const viewDetailsModals = (data) => {
    setOpenViewModal(true)
    setOpenExclusiveService(data);
}

  const columns = [
    {
      name: 'Company Bussiness Name',
      id: 'bussiness',
      sortable: false,
      span: 2,
      renderer: (service) => (
        <div className={`col-12 d-inline-flex align-items-center col-12 gap-3`}>
          <span className={`${styles.courseImag} flex-shrink-0 d-inline-flex align-items-center text-uppercase justify-content-center`}>{service?.company_bussiness_name?.trim()?.charAt(0)}</span>
          <div className='flex-grow-1 d-flex flex-column'>
            <h6 className={`${styles.courseName} col-12 text-truncate`} title={service?.company_bussiness_name}>{service?.company_bussiness_name} </h6>
            <span className={`${styles.courseTiming} d-inline-flex col-12`}>{formatDate(service?.createdAt) + " " + formatTime(service?.createdAt)}</span>
            <span className={`${styles.courseTimeDifference} d-inline-flex col-12`}>{calculateTimeDifference(service?.createdAt)}</span>
          </div>
        </div>
      )
    },
    {
      name: 'Email',
      id: 'email',
      renderer: (service) => (
        <div className='col-12 d-inline-flex align-items-center gap-2'>
          <span className={`${styles.authourImg} flex-shrink-0 d-inline-flex align-items-center justify-content-center`}>{service?.email?.charAt(0)}</span>
          <span className={`${styles.authorName} d-inline-flex`}>{service?.email}</span>
        </div>
      )
    },
    {
      name: 'Course Category',
      id: 'Course',
      renderer: (service) => (
        <span className={`${styles.tableData}`}> {service?.course_category_name ?? "-"}</span>
      )
    },
    {
      name: 'Delivery Method',
      id: 'Delivery',
      renderer: (service) => (
        <span className={`${styles.tableData}`}> {service?.delivery_method_name ?? "-"}</span>
      )
    },
    {
      name: 'Message',
      id: 'message',
      renderer: (service) => (
        <span className={`${styles.tableData}`}> {service?.message ?? "-"}</span>
      )
    },
    {
      name: 'Action',
      id: 'action',
      renderer: (status) => (
        <div className='col-12 d-inline-flex align-items-center gap-2'>
          <React.Fragment>
            <span type="button" className={`${styles.rejectBtn} d-inline-flex align-items-center justify-content-center px-4`} onClick={() => viewDetailsModals(status)}  >View</span>
          </React.Fragment>
        </div>
      )
    }
  ];


  return (
    <React.Fragment>
      <div className={`${styles.courseListContainer} d-inline-flex flex-column overflow-hidden`}>
        <CommonTable cols={columns} rows={exclusiveServiceList?.data} />
        <div className='px-4'>
          <CustomPagination
            totalCount={exclusiveServiceList?.rows}
            onChange={(n) => {
              setSelectedFilter((prev) => ({
                ...prev,
                pageNumber: n
              }))
            }}
          // activePage={1}
          />
        </div>
        {/* {isOpen === true &&
          <ChangeStatusModal
            setIsOpen={setIsOpen}
            listData={openCourse}
            setActiveTab={setActiveTab}
            title={`Do you want to status of this course ${openCourse?.name}`}
            handleApprove={handleApprove}
          />
        } */}

        {openViewModal === true &&
          <ExclusiveServiceModal
            setIsOpen={setOpenViewModal}
            listData={openExclusiveService}
          />
        }
      </div>
    </React.Fragment>
  )
}

export default ExclusiveServiceList