import React, { useEffect, useState } from 'react';
import styles from './User.module.css';
import { Layout } from '../../Components/Layout/Layout';
import { PageHeader } from '../../Components/PageHeader/PageHeader';
import { UserList } from '../../Components/UserList/UserList';
import { TableTabHeader } from '../../Components/TableTabHeader/TableTabHeader';
import { getUserConstData, getUsers } from '../../services/user.service';
import toast from 'react-hot-toast';

export const User = () => {
  const courseTab = ['All', 'Most Recent'];
  const [usersList, setUsersList] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const [userSearchDateType, setUserSearchDateType] = useState([])
  const [selectedFilter, setSelectedFilter] = useState({
    pageNumber: 1,
    date_range: "all",
    name: ""
  })


  const FilterData = Object.entries(userSearchDateType).map(([value, label]) => ({
    value,
    label
  }));

  const getCourseSearchDateType = async () => {
    const result = await getUserConstData();
    if (result.status) {
      setUserSearchDateType(result?.data?.appliedJobDateRangeObj);
    } else {
      toast.error(result?.error);
    }
  }

  const getUsersList = async () => {
    const filters = {
      page_no: selectedFilter?.pageNumber,
      date_range: selectedFilter?.date_range,
      name: selectedFilter?.name,
    }
    if (activeTab === "Most Recent") {
      delete filters.approved_by_admin;
      filters.most_resent = true
    }

    const result = await getUsers(filters);
    if (result.status) {
      setUsersList(result);
    }
  }

  useEffect(() => {
    getUsersList()
    getCourseSearchDateType()
  }, [activeTab, selectedFilter])

  return (
    <Layout>
      <div className='col-12 d-inline-flex flex-column pt-4 px-4'>
        <PageHeader
          title="User"
          filterDateType={FilterData}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          type={"filter"}
        />
        <div className='col-12 d-inline-flex flex-column mt-4'>
          <TableTabHeader
            courseTab={courseTab}
            searchPlaceholder="Search Jobs"
            onTabClick={(item) => {
              setActiveTab(item);
              setSelectedFilter((prev) => ({
                ...prev,
                pageNumber: 1
              }))
            }}
            setActiveTab={setActiveTab}
            setSelectedFilter={setSelectedFilter}
          />
          <UserList
            usersList={usersList}
            getUsersList={getUsersList}
            setSelectedFilter={setSelectedFilter}
            setActiveTab={setActiveTab}
          />
        </div>
      </div>
    </Layout>
  )
}