import axios from "axios";
import { ApiUrls } from "../ApiUrls/apiUrls";
import { axiosInstance } from "../ApiUrls/Interceptors";


/**
 * 
 * @param {Objext} filterRequestDto 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get all Job List
 */
export const getJobList = async (filters) => {
  try {
    const result = await axiosInstance.get(ApiUrls.GET_JOB_LIST,
      {
        params: filters
      });

    return {
      status: true,
      rows: result?.data?.data?.list?.rows,
      data: result?.data?.data?.list?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}

/**
 * 
 * @returns @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get all Const Data related to Job
 */
export const getJobConstData = async () => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_JOB_CONST_DATA}`);

    return {
      status: true,
      data: result?.data?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}

/**
 * 
 * @param {Object} data 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Update the Status of job status
 */
export const updateJobStatus = async (data) => {
  try {
    const result = await axiosInstance.post(`${ApiUrls.UPDATE_JOB_STATUS}`, data);

    return {
      status: true,
      data: result?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}

/**
 * 
 * @param {Object} filters 
 * @returns  {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get all Category List
 */
export const getCategoryList = async (filters) => {
  try {
    const result = await axiosInstance.get(ApiUrls.GET_CATEGORY_LIST,
      {
        params: filters
      });

    return {
      status: true,
      rows: result?.data?.data?.jobCategories?.rows,
      data: result?.data?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}


export const createCategory = async (data) => {
  try {
    const result = await axiosInstance.post(`${ApiUrls.CREATE_CATEGORY}`, data);

    return {
      status: true,
      data: result?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}

export const updateCategory = async (data, id) => {
  try {
    const result = await axiosInstance.patch(`${ApiUrls.UPDATE_CATEGORY}/${id}`, data);

    return {
      status: true,
      data: result?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}