import React, { useEffect } from 'react';
import { PublicRoutes } from './routes/PublicRoutes';
import { Toaster } from "react-hot-toast";
import { useAuth } from './hook/useAuth';
import { useNavigate } from 'react-router-dom';

export const Main = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();


  useEffect(() => {
    if (!auth.isLoggedIn) {
      navigate('/login');
    }
  }, [auth.isLoggedIn]);

  return (
    <React.Fragment>
      <Toaster position="top-right" reverseOrder={false} />
      <PublicRoutes />
    </React.Fragment>
  )
}