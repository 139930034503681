import React, { useEffect, useState } from 'react';
import { Layout } from '../../Components/Layout/Layout';
import { PageHeader } from '../../Components/PageHeader/PageHeader';
import { TableTabHeader } from '../../Components/TableTabHeader/TableTabHeader';
import { PurchasersList } from '../../Components/PurchasersList/PurchasersList';
import { getAllPurchases } from '../../services/billing.service';
import { formatDate } from '../../utils/DateTimeFormat';

export const Purchasers = () => {
    const [allPurchasersList, setAllPurchasersList] = useState([])
    const courseTab = ['All'];
    const [selectedFilter, setSelectedFilter] = useState({
        pageNumber: 1,
        name: "",
        start_date: '',
        end_date: '',
        // start_date: formatDate(new Date()),
        // end_date: formatDate(new Date()),
    })

    const getAllPurchasersList = async () => {
        let filters = {
            page_no: selectedFilter?.pageNumber,
            search_keyword: selectedFilter?.name,
        }
        if(selectedFilter?.start_date !== '') {
            filters['start_date'] = selectedFilter?.start_date
        }
        if(selectedFilter?.end_date !== '') {
            filters['end_date'] = selectedFilter?.end_date
        }
        // if (activeTab !== "All") {
        //     filters.approved_by_admin = activeTab;
        // }
        const result = await getAllPurchases(filters);

        if (result.status) {
            setAllPurchasersList(result);
        }
    };

    useEffect(() => {
        getAllPurchasersList();
    }, [selectedFilter])

    return (
        <Layout>
            <div className='col-12 d-inline-flex flex-column p-4'>
                <PageHeader
                    title="All Purchasers"
                    selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                />
                <div className='col-12 d-inline-flex flex-column mt-4'>
                    <TableTabHeader
                        courseTab={courseTab}
                        searchPlaceholder="Search Orders"
                        setSelectedFilter={setSelectedFilter}
                        onTabClick={(item) => {
                            setSelectedFilter((prev) => ({
                                ...prev,
                                pageNumber: 1
                            }))
                        }}
                    />
                    <PurchasersList
                        allPurchasersList={allPurchasersList}
                        setSelectedFilter={setSelectedFilter}
                    />
                </div>
            </div>
        </Layout>
    )
}