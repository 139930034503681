import React, { useEffect, useState } from 'react';
import { Layout } from '../../Components/Layout/Layout';
import { PageHeader } from '../../Components/PageHeader/PageHeader';
import { TableTabHeader } from '../../Components/TableTabHeader/TableTabHeader';
import ExclusiveServiceList from '../../Components/ExclusiveServiceList/ExclusiveServiceList';
import { getExclusiveServiceList } from '../../services/dashboard.service';

const ExclusiveService = () => {
  const [exclusiveServiceList, setExclusiveServiceList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({
    pageNumber: 1,
    start_date: "",
    end_date: "",
    name: "",
  })

  const getExclusiveService = async (data) => {
    // const filters = {
    //   page_no: selectedFilter?.pageNumber,
    //   user_name: selectedFilter?.name,
    //   start_date: selectedFilter?.start_date,
    //   end_date: selectedFilter?.end_date,
    // }

    // if (activeTab !== "All") {
    //   filters.approved_by_admin = activeTab;
    const result = await getExclusiveServiceList();

    if (result.status) {
      setExclusiveServiceList(result);
    }
  }

  useEffect(() => {
    getExclusiveService()
  }, [])

  return (
    <Layout>
      <div className='col-12 d-inline-flex flex-column p-4'>
        <PageHeader
          title="Exclusive Service"
        // filterDateType={courseSearchDateType}
        // selectedFilter={selectedFilter}
        // setSelectedFilter={setSelectedFilter}
        // type={"filter"}
        />
        <div className='col-12 d-inline-flex flex-column mt-4'>
          {/* <TableTabHeader
          courseTab={courseTab}
          searchPlaceholder="Search Courses"
          activeTab={activeTab}
          setSelectedFilter={setSelectedFilter}
          onTabClick={(item) => {
            setActiveTab(item);
            setSelectedFilter((prev) => ({
              ...prev,
              pageNumber: 1
            }))
          }}
          /> */}
          <ExclusiveServiceList
            exclusiveServiceList={exclusiveServiceList}
            // getAllCoursesList={getAllCoursesList}
            // setActiveTab={setActiveTab}
            setSelectedFilter={setSelectedFilter}
          />
        </div>
      </div>
    </Layout>
  )
}

export default ExclusiveService