import React, { useEffect, useState } from 'react';
import styles from './Header.module.css';
import { ClockIcon, HamburgerIcon, NotificationIcon } from '../../siteicons';
import OutsideClick from '../../Components/OutsideClick';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hook/useAuth';

export const Header = ({ setSideBarHide, sideBarHide }) => {
	const { auth, setAuth, profile, getUserProfile } = useAuth();
	const [userDrop, setUserDrop] = useState(false);
	const [notifyDrop, setNotifyDrop] = useState(false);
	const navigate = useNavigate();

	const openAccountDetail = () => {
		if (userDrop === false) {
			setUserDrop(true);
		} else {
			setUserDrop(false);
		}
	}

	const hideAsideBar = () => {
		if (sideBarHide === false) {
			setSideBarHide(true);
		} else {
			setSideBarHide(false);
		}
	}

	const openNotifyBox = () => {
		if (notifyDrop === false) {
			setNotifyDrop(true);
		} else {
			setNotifyDrop(false);
		}
	}

	const signOut = () => {
		localStorage.removeItem('access_token');
		localStorage.removeItem('refresh_token');
		setAuth({ ...auth, isLoggedIn: false });
		navigate('/login');
	};

	useEffect(() => {
    getUserProfile();
  }, []);

	return (
		auth.isLoggedIn &&
		<React.Fragment>
			<div className={`${styles.headerContainer} col-12 d-inline-flex align-items-center justify-content-between px-2`}>
				<div className={`${styles.leftNavBox} d-inline-flex align-items-center`}>
					<span role="button" onClick={() => hideAsideBar()} className={`${styles.leftAsideIcon} d-inline-flex align-items-center justify-content-center`}>
						<HamburgerIcon color="#000" />
					</span>
				</div>
				<div className={`${styles.rightNavBox} d-inline-flex align-items-center gap-3 pe-4 position-relative`}>
					<OutsideClick onOutsideClick={() => { setNotifyDrop(false); }}>
						<span role="button" className={`${styles.notifyIcon} d-inline-flex align-items-center justify-content-center`} onClick={() => openNotifyBox()}>
							<NotificationIcon color="#000" />
						</span>

						<div className={`${styles.notificationBox} ${notifyDrop === true && styles.notifyOpenBox} position-absolute d-inline-flex flex-column`}>
							<h4 className={`${styles.cardTitle} col-12 d-inline-flex p-3 px-4`}>Notifications</h4>
							<div className={`${styles.notifiyBlock} col-12 d-inline-flex align-items-start gap-2 p-3`}>
								<span className={`${styles.notifyUserBox} d-inline-flex align-items-center justify-content-center flex-shrink-0`}>K</span>
								<div className='flex-grow-1 d-inline-flex flex-column gap-1'>
									<h5 className={`${styles.notifyUserName} col-12 d-inline-flex mb-0`}>Kristin Watson</h5>
									<p className={`${styles.notifyUserDesc} col-12 d-inline-flex mb-0`}>Krisitn Watsan like your comment on course Javascript Introduction!</p>
									<div className={`${styles.notifyTimeBox} col-12 d-inline-flex align-items-center gap-2 mt-2`}>
										<ClockIcon color="#64748B" />
										<span className={`${styles.notifyTime} d-inline-flex`}>Oct 9, 2:19 PM</span>
									</div>
								</div>
							</div>
						</div>
					</OutsideClick>

					<OutsideClick onOutsideClick={() => { setUserDrop(false); }}>
						<span role="button" className={`${styles.userInitial} d-inline-flex align-items-center justify-content-center`} onClick={() => openAccountDetail()}>
						{profile?.full_name?.trim()?.charAt(0)}
						</span>

						<div className={`${styles.accountBox} ${userDrop === true && styles.accountOpenBox} position-absolute d-inline-flex flex-column pb-2`}>
							<div className={`${styles.avatarUserBox} col-12 d-inline-flex align-items-start flex-column p-3 mb-2`}>
								<span className={`${styles.avatarName} d-inline-flex col-12 px-1`}>{profile?.full_name}</span>
								<span className={`${styles.avatarEmail} d-inline-flex col-12 px-1`}>{profile?.email}</span>
							</div>
							<div role="button" className={`${styles.userDropLinks} d-inline-flex align-items-center col-12 px-4`}>Profile</div>
							<div role="button" className={`${styles.userDropLinks} d-inline-flex align-items-center col-12 px-4`}>Settings</div>
							<div
								role="button"
								className={`${styles.userDropLinks} d-inline-flex align-items-center col-12 px-4`}
								onClick={signOut}
							>Sign Out
							</div>
						</div>
					</OutsideClick>
				</div>
			</div>
		</React.Fragment>
	);
};