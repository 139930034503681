import axios from "axios";
import {jwtDecode} from "jwt-decode";
import moment from "moment";
import { toast } from "react-hot-toast";


export const getToken = () => {
  if (typeof window != 'undefined') {
    const accessToken = localStorage.getItem('access_token');
    return accessToken;
  }
};

export const getAuthorizationHeader = () => {
  const accessToken = getToken();
  return accessToken;
};

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_APP_FULL_API_URL,
  headers: {
    authorization: `Bearer ${getAuthorizationHeader()}`
  }
});

axiosInstance.interceptors.request.use(async req => {
  let accessToken = localStorage.getItem('access_token');
  let refreshToken = localStorage.getItem('refresh_token');
  req.headers.Authorization = `Bearer ${accessToken}`;

  const user = jwtDecode(accessToken);
  const isExpired = moment.unix(user.exp).diff(moment()) < 1;

  if (!isExpired) return req;
  try {
    const response = await axios.get(process.env.REACT_APP_APP_FULL_API_URL + '/auth/refreshToken', {
      headers: {
        refreshToken: refreshToken
      }
    });

    if (response.status === 200) {
      accessToken = response?.data?.data?.access_token;
      refreshToken = response?.data?.data?.refresh_token;
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('refresh_token', refreshToken);
      req.headers.Authorization = `Bearer ${accessToken}`;
    }
    return req;
  } catch (error) {
    if (error?.response.status === 401) {
      window.location.href("/login");
      window.localStorage.removeItem("access_token");
      window.localStorage.removeItem("access_token")
      toast.error('Session expired, Please login again.');
    }
  }
});