import React, { useEffect, useState } from 'react';
import { Layout } from '../../Components/Layout/Layout';
import { PageHeader } from '../../Components/PageHeader/PageHeader';
import { CustomerHeader } from '../../Components/CustomerHeader/CustomerHeader';
import { RecentPayments } from '../../Components/RecentPayments/RecentPayments';
import { useParams } from 'react-router-dom';
import { getBIllingEmployerDetails, getBIllingEmployerDetailsList } from '../../services/billing.service';

export const CustomerSummery = () => {
    const { id } = useParams();
    const [bIllingEmployerDetail, setBIllingEmployerDetail] = useState({});
    const [bIllingEmployerDetailList, setBIllingEmployerDetailList] = useState([]);

    const getBIllingEmployerDetail = async () => {
        const result = await getBIllingEmployerDetails(id);

        if (result.status) {
            setBIllingEmployerDetail(result?.data)
        }
    }

    const getBIllingEmployerDetailList = async () => {
        const result = await getBIllingEmployerDetailsList(id);

        if (result.status) {
            setBIllingEmployerDetailList(result)
        }
    }

    useEffect(() => {
        getBIllingEmployerDetail();
        getBIllingEmployerDetailList()
    }, [id])


    return (
        <Layout>
            <div className='col-12 d-inline-flex flex-column p-4'>
                <PageHeader title="Customer Summary" />
                <div className='col-12 d-inline-flex flex-column gap-4 mt-4'>
                    <CustomerHeader
                        bIllingEmployerDetailList={bIllingEmployerDetail}
                    />
                    <RecentPayments
                        bIllingEmployerDetailList={bIllingEmployerDetailList}
                    />
                </div>
            </div>
        </Layout>
    )
}