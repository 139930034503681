import React, { useState } from 'react';
import Select from 'react-select';

const CustomDateRangePicker = ({ courseSearchDateType, selectedFilter, setSelectedFilter }) => {

  return (
    <>
      <div className="App">
        <Select
          defaultValue={selectedFilter?.date_range}
          onChange={(n) => {
            setSelectedFilter((prev) => ({
              ...prev,
              date_range: n?.value,
              pageNumber: 1
            }))
          }}
          options={courseSearchDateType}
        />
      </div>
    </>
  )
}

export default CustomDateRangePicker