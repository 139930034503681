import React from 'react';
import styles from './AlertInfo.module.css';
import { formatDate, formatTime } from '../../utils/DateTimeFormat';

export const AlertInfo = ({ alertDetail, updateAlert }) => {
    const id = alertDetail?.id;

    const handleChangeStatus = async (statusId) => {
        const formData = {
            id: id,
            message: alertDetail?.message,
            pages: [alertDetail?.pages],
            status: statusId.toString()
        }
        updateAlert(formData, id)
    }

    return (
        <React.Fragment>
            <div className='col-12 d-inline-flex mt-5 gap-4 flex-column'>
                <div className={`${styles.dashboardBlock} col-12 d-inline-flex flex-column mt-2`}>
                    <h2 className={`${styles.cardTitle} col-12 d-inline-flex p-4`}>Alert Info</h2>
                    <h6 className={`${styles.alertInfoMessag} col-12 d-inline-flex mb-0 p-3`}>
                        {alertDetail?.message}
                    </h6>
                    <div className='col-12 d-inline-flex flex-column'>
                        <div className={`${styles.statusBox} d-inline-flex flex-column align-items-start p-3`}>
                            <label className={`${styles.statusLabel} d-inline-flex`}>Status</label>
                            <span className={`${alertDetail?.status === "1" ? styles.statusPending : alertDetail?.status === "2" ? styles.statusPaid : alertDetail?.status === "0" ? styles.statusRefunded : ""} d-inline-flex align-items-center`}>{alertDetail?.statusName}</span>
                        </div>
                        <div className={`${styles.statusBox} d-inline-flex flex-column align-items-start p-3`}>
                            <label className={`${styles.statusLabel} d-inline-flex`}>Created At</label>
                            <label className={`${styles.statusValue} d-inline-flex`}>{formatDate(alertDetail?.createdAt) + " " + formatTime(alertDetail?.createdAt)}</label>
                        </div>
                        <div className={`${styles.statusBox} d-inline-flex flex-column align-items-start p-3`}>
                            <label className={`${styles.statusLabel}`}>Last Update</label>
                            <label className={`${styles.statusValue} d-inline-flex`}>{formatDate(alertDetail?.updatedAt) + " " + formatTime(alertDetail?.updatedAt)}</label>
                        </div>
                    </div>
                </div>
                <div className={`${styles.dashboardBlock} col-12 d-inline-flex flex-column mt-2`}>
                    <h2 className={`${styles.cardTitle} col-12 d-inline-flex p-4 mb-0`}>Actions</h2>
                    <div className='col-12 d-inline-flex flex-column'>
                        <div className={`${styles.statusBox} d-inline-flex flex-column p-3`}>
                            <span role="button" className={`${styles.statusLabel} d-inline-flex align-items-center justify-content-center`}
                                onClick={() => handleChangeStatus(1)} >Unpublished</span>
                        </div>
                        <div className={`${styles.statusBox} d-inline-flex flex-column p-3`}>
                            <span role="button" className={`${styles.statusLabel} d-inline-flex align-items-center justify-content-center`}>Change</span>
                        </div>
                        <div className={`${styles.statusBox} d-inline-flex flex-column p-3`}>
                            <span role="button" className={`${styles.statusLabel} d-inline-flex align-items-center justify-content-center`} onClick={""} >Delete</span>
                        </div>
                    </div>
                </div>
                <div className={`${styles.dashboardBlock} col-12 d-inline-flex flex-column mt-2`}>
                    <h2 className={`${styles.cardTitle} col-12 d-inline-flex p-4`}>Revision History</h2>
                    <div className='col-12 d-inline-flex flex-column'>
                        <div className={`${styles.statusBox} d-inline-flex gap-4 p-3`}>
                            <label className={`${styles.statusValue} d-inline-flex`}>{formatDate(alertDetail?.createdAt) + " " + formatTime(alertDetail?.createdAt)}</label>
                            <span className={`${alertDetail?.status === "1" ? styles.statusPending : alertDetail?.status === "2" ? styles.statusPaid : alertDetail?.status === "0" ? styles.statusRefunded : ""} d-inline-flex align-items-center`}>{alertDetail?.statusName}</span>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}