import React from 'react'
import styles from './AlertList.module.css';
import CommonTable from '../CommonTable/CommonTable';
import { calculateTimeDifference, formatDate, formatTime } from '../../utils/DateTimeFormat';
import { deleteAlert } from '../../services/alert.service';
import toast from 'react-hot-toast';
import CustomPagination from '../../common/CustomPagination';
// import CustomPagination from '../../common/CustomPagination';

const AlertList = ({ alertList, getAllAlertList, setSelectedFilter, getAlertDetail }) => {

  const handleDelete = async (status) => {
    const id = status?.id;
    const result = await deleteAlert(id);

    if (result.status) {
      getAllAlertList()
      toast.success(result?.msg)
    } else {
      toast.error(result?.error);
    }
  }

  const columns = [
    {
      name: 'Message',
      id: 'message',
      sortable: false,
      span:2,
      renderer: (alert) => (
        <div className={`col-12 d-inline-flex align-items-center col-12 gap-3`} onClick={()=> getAlertDetail(alert)} >
          <div className='flex-grow-1 d-flex flex-column'>
            <h6 className={`${styles.alertName} col-12 text-truncate`} title={alert?.name} >{alert?.message} </h6>
            <span className={`${styles.alertTiming} d-inline-flex col-12`}>{formatDate(alert?.createdAt) + " " + formatTime(alert?.createdAt)}</span>
            <span className={`${styles.alertTimeDifference} d-inline-flex col-12`}>{calculateTimeDifference(alert?.createdAt)}</span>
          </div>
        </div>
      )
    },
    {
      name: 'Pages',
      id: 'pages',
      renderer: (alert) => (
        <div className='col-12 d-inline-flex align-items-center gap-2'>
          <span className={`${styles.authorName} d-inline-flex`}>{alert?.pages}</span>
        </div>
      )
    },
    {
      name: 'Status',
      id: 'Status',
      renderer: (status) => (
        <span className={`${styles.tableData}`}>
          <span className={`${styles.alertStatus} ${status?.status === "1" ? styles.alertPending : status?.status === "2" ? styles.alertLive : status?.status === "0" ? styles.alertDraft : ""} d-inline-flex`}></span> {status?.statusName}
        </span>
      )
    },
    {
      name: 'Action',
      id: 'action',
      renderer: (status) => (
        <div className='col-12 d-inline-flex align-items-center gap-2'>
          <React.Fragment>
            <span role='button' className={`${styles.rejectBtn} d-inline-flex align-items-center justify-content-center px-3`} onClick={() => handleDelete(status)} >Delete</span>
          </React.Fragment>
        </div>
      )
    }
  ];

  return (
    <React.Fragment>
      <div className={`${styles.AlertListContainer} d-inline-flex flex-column overflow-hidden`}>
        <CommonTable cols={columns} rows={alertList?.data} />
        <div className='px-4'>
          <CustomPagination
            totalCount={alertList?.rows}
            onChange={(n) => {
              setSelectedFilter((prev) => ({
                ...prev,
                pageNumber: n
              }))
            }}
          // activePage={1}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default AlertList