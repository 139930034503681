import React from 'react';
import styles from './PurchasersList.module.css'
import { Link } from 'react-router-dom';
import CommonTable from '../CommonTable/CommonTable';
import { formatDate, formatTime } from '../../utils/DateTimeFormat';
import CustomPagination from '../../common/CustomPagination';

export const PurchasersList = (props) => {
    const { allPurchasersList, setSelectedFilter } = props;

    const columns = [
        {
            name: 'Order',
            id: 'Order',
            renderer: (order) => (
                <Link to={`/order-summary/${order?.order_id}`} className={`${styles.orderLink} d-inline-flex text-decoration-none`} role="button">{order?.order_id}</Link>
            )
        },
        {
            name: 'Customer',
            id: 'Customer',
            span: 2,
            renderer: (customer) => (
                <span className={`${styles.tableData}`}>{customer?.customer_name}</span>
            )
        },
        {
            name: 'Date',
            id: 'Date',
            renderer: (date) => (
                <span className={`${styles.tableData}`}>{formatDate(date?.created_at) + " " + formatTime(date?.created_at)}</span>
            )
        },
        {
            name: 'Items',
            id: 'items',
            renderer: ({ items }) => (
                <span className={`${styles.tableData}`}>{items + " " + "Items"}</span>
            )
        },
        {
            name: 'Payment',
            id: 'payment',
            renderer: (payment) => (
                <div className='col-12 d-inline-flex align-items-center gap-2'>
                    {payment?.payment_status === '1' ? (
                        <span className={`${styles.statusFailed} d-inline-flex align-items-center`}>{payment?.payment_status_name}</span>
                    ) : payment?.payment_status === '3' ? (
                        <span className={`${styles.statusRefunded} d-inline-flex align-items-center`}>{payment?.payment_status_name}</span>
                    ) : payment?.payment_status === '0' ? (
                        <span className={`${styles.statusPending} d-inline-flex align-items-center`}>{payment?.payment_status_name}</span>
                    ) : payment?.payment_status === '2' ? (
                        <span className={`${styles.statusPaid} d-inline-flex align-items-center`}>{payment?.payment_status_name}</span>
                    ) : ''}
                </div>
            )
        },
        {
            name: 'Total',
            id: 'Total',
            renderer: ({ total }) => (
                <span className={`${styles.tableData}`}>${total}</span>
            )
        }
    ];

    return (
        <React.Fragment>
            <div className={`${styles.courseListContainer} d-inline-flex flex-column overflow-hidden`}>
                <CommonTable cols={columns} rows={allPurchasersList?.data} />
                <div className='px-4'>
                    <CustomPagination
                        totalCount={allPurchasersList?.rows}
                        onChange={(n) => {
                            setSelectedFilter((prev) => ({
                                ...prev,
                                pageNumber: n
                            }))
                        }}
                    // activePage={1}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}