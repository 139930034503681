import { createContext, react, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { getProfile } from "../services/user.service";
import toast from "react-hot-toast";

const initial = {
  isLoggedIn: localStorage.getItem('access_token') ? true : false,
  user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
  activeNavId: localStorage.getItem("activeNavId") ? localStorage.getItem("activeNavId") : [],
};

export const AuthContext = createContext({
  settings: initial
});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ ...initial });
  const [profile, setProfile] = useState(null);

  /**
   * @comment for Geting the User Profile data
   */
  const getUserProfile = async () => {
    const result = await getProfile();

    if (result.status) {
      setProfile(result?.data)
    }
  }

  return <AuthContext.Provider value={{ auth, setAuth, getUserProfile, profile }}>{children}</AuthContext.Provider>
};