import React, { useState, useRef, useEffect } from 'react';
import styles from './Dashboard.module.css';
import { Layout } from '../../Components/Layout/Layout';
import { CandidateIcon, EmployerIcon, LearnerIcon, NegativTrendIcon, SalesIcon, TrendIcon } from '../../siteicons';
import { PageHeader } from '../../Components/PageHeader/PageHeader';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useAuth } from '../../hook/useAuth';
import { getDashboardData, getDayList } from '../../services/dashboard.service';
import { MonthNames, monthNames } from '../../utils/DateTimeFormat';
import toast from 'react-hot-toast';
import { getCoursesList } from '../../services/courses.service';
import { Link } from 'react-router-dom';

export const Dashboard = () => {
  const [searchDate, setSearchDate] = useState([]);
  const [dashboardData, setDashboardData] = useState();
  const [coursesList, setCoursesList] = useState([])
  const dateRef = useRef(null);
  const { auth } = useAuth();
  const [selectedFilter, setSelectedFilter] = useState({
    date_range: "all",
  })

  const getDashboardDayList = async () => {
    const result = await getDayList();
    if (result.status) {
      setSearchDate(result?.data?.numberOfDaysForTraffic
      )
    } else {
      toast.error(result?.error);
    }
  }

  const getAllCoursesList = async () => {
    const result = await getCoursesList();

    if (result.status) {
      setCoursesList(result?.data);
    }
  };


  const getAllDashboardData = async () => {
    const result = await getDashboardData();
    if (result.status) {
      setDashboardData(result?.data);
    }
  }

  useEffect(() => {
    getAllDashboardData()
    getDashboardDayList()
    getAllCoursesList()
  }, []);

  useEffect(() => {
    console.log(dateRef);
    console.log({ auth });
  }, []);

  const EarnOptions = {
    chart: {
      type: 'spline',
    },
    title: {
      text: 'Per Month Order Data',
    },
    xAxis: {
      // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      categories: dashboardData?.perMonthOrderData?.map(x => MonthNames[x?.month_date - 1])
    },
    yAxis: {
      title: {
        text: 'Total Sale'
      },
      labels: {
        format: '{value}K'
      }
    },
    tooltip: {
      valueSuffix: 'k'
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      spline: {
        lineWidth: 4,
        states: {
          hover: {
            lineWidth: 5
          }
        },
        marker: {
          enabled: false
        }
      }
    },
    series: [{
      name: 'Month (M)',
      // data: [5.4, 5.2, 5.7, 6.3, 5.2, 5.6, 6.1, 5.6, 5.9, 7.1],
      data: dashboardData?.perMonthOrderData?.map(y => y?.total_sale)
    }],
    navigation: {
      menuItemStyle: {
        fontSize: '10px'
      }
    }
  }

  const TrafficOptions = {
    title: {
      text: 'Per Month Order Data',
    },
    legend: {
      enabled: false
    },
    tooltip: {
      valueDecimals: 2,
      valueSuffix: ' TWh'
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        colorByPoint: true,
        type: 'pie',
        size: '100%',
        innerSize: '70%',
        dataLabels: {
          enabled: true,
          crop: false,
          distance: '-10%',
          style: {
            fontWeight: 'bold',
            fontSize: '16px'
          },
          connectorWidth: 0
        }
      }
    },
    colors: ['#B8E8FC', '#BCE29E', '#f178b6', '#5d5fef'],
    series: [
      {
        type: 'pie',
        name: 'Percentage',
        data: [
          {
            name: 'Sales',
            y: 25.02
          },
          {
            name: 'Employers',
            y: 55.02
          },
          {
            name: 'Candidates',
            y: 15.5
          },
          {
            name: 'Course Learners',
            y: 1.68
          }
        ]
      }
    ]
  }

  return (
    <React.Fragment>
      <Layout nav="Overview">
        <div className='col-12 d-inline-flex flex-column p-4'>
          <PageHeader
            title="Dashboard"
            type="filter"
            filterDateType={searchDate}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
          <div className='col-12 d-inline-flex align-items-stretch gap-4 pt-4'>
            <div className={`${styles.dashboardBlock} col-3 d-inline-flex flex-column p-3 flex-shrink-1`}>
              <div className='col-12 d-inline-flex align-items-center justify-content-between mb-3'>
                <span className={`${styles.blockTitle} text-uppercase d-inline-flex`}>Sales</span>
                <span className={`${styles.blockIconBox} d-inline-flex`}><SalesIcon color="#5D5FEF" /></span>
              </div>
              <h2 className={`${styles.dashboardStats} d-inline-flex col-12`}>{dashboardData?.totalSale === null ? "0" : dashboardData?.totalSale}</h2>
              <div className='col-12 d-inline-flex align-items-center gap-2'>
                <span className={`${dashboardData?.totalSaleGrowth > 0 ? styles.statsStatus : dashboardData?.totalSaleGrowth < 0 ? styles.negativeStatus : styles.statsStatus} d-inline-flex align-items-center gap-1`}>
                  <span>
                    {dashboardData?.totalSaleGrowth >= 0 ? (<TrendIcon color="#139a74" />) : (<NegativTrendIcon color="red" />)}
                    {dashboardData?.totalSaleGrowth}
                    {dashboardData?.totalSaleGrowth >= 0 ? '+' : '-'}
                  </span>
                </span>
                <span className={`${styles.statsTitle} d-inline-flex`}>Number of sales</span>
              </div>
            </div>
            <div className={`${styles.dashboardBlock} col-3 d-inline-flex flex-column p-3 flex-shrink-1`}>
              <div className='col-12 d-inline-flex align-items-center justify-content-between mb-3'>
                <span className={`${styles.blockTitle} text-uppercase d-inline-flex`}>Candidates</span>
                <span className={`${styles.blockIconBox} d-inline-flex`}><CandidateIcon color="#5D5FEF" /></span>
              </div>
              <h2 className={`${styles.dashboardStats} d-inline-flex col-12`}>{dashboardData?.totalCandidates}</h2>
              <div className='col-12 d-inline-flex align-items-center gap-2'>
                <span className={`${dashboardData?.candidateGrouth > 0 ? styles.statsStatus : dashboardData?.candidateGrouth < 0 ? styles.negativeStatus : styles.statsStatus} d-inline-flex align-items-center gap-1`}>
                  <span>
                    {dashboardData?.candidateGrouth >= 0 ? (<TrendIcon color="#139a74" />) : (<NegativTrendIcon color="red" />)}
                    {dashboardData?.candidateGrouth}
                    {dashboardData?.candidateGrouth >= 0 ? '+' : '-'}
                  </span>
                </span>
                <span className={`${styles.statsTitle} d-inline-flex`}>Candidates</span>
              </div>
            </div>
            <div className={`${styles.dashboardBlock} col-3 d-inline-flex flex-column p-3 flex-shrink-1`}>
              <div className='col-12 d-inline-flex align-items-center justify-content-between mb-3'>
                <span className={`${styles.blockTitle} text-uppercase d-inline-flex`}>Employers</span>
                <span className={`${styles.blockIconBox} d-inline-flex`}><EmployerIcon color="#5D5FEF" /></span>
              </div>
              <h2 className={`${styles.dashboardStats} d-inline-flex col-12`}>{dashboardData?.totalEmployers}</h2>
              <div className='col-12 d-inline-flex align-items-center gap-2'>
                <span className={`${dashboardData?.employerGrouth >= 0 ? styles.statsStatus : dashboardData?.employerGrouth < 0 ? styles.negativeStatus : styles.statsStatus} d-inline-flex align-items-center gap-1`}>
                  <span>
                    {dashboardData?.employerGrouth >= 0 ? (<TrendIcon color="#139a74" />) : (<NegativTrendIcon color="red" />)}
                    {dashboardData?.employerGrouth}
                    {dashboardData?.employerGrouth >= 0 ? '+' : '-'}
                  </span>
                </span>
                <span className={`${styles.statsTitle} d-inline-flex`}>Employers</span>
              </div>
            </div>
            <div className={`${styles.dashboardBlock} col-3 d-inline-flex flex-column p-3 flex-shrink-1`}>
              <div className='col-12 d-inline-flex align-items-center justify-content-between mb-3'>
                <span className={`${styles.blockTitle} text-uppercase d-inline-flex`}>Course Learners</span>
                <span className={`${styles.blockIconBox} d-inline-flex`}><LearnerIcon color="#5D5FEF" /></span>
              </div>
              <h2 className={`${styles.dashboardStats} d-inline-flex col-12`}>{dashboardData?.totalCourseLearners}</h2>
              <div className='col-12 d-inline-flex align-items-center gap-2'>
                <span className={`${dashboardData?.totalCourseLearnersGrowth > 0 ? styles.statsStatus : dashboardData?.totalCourseLearnersGrowth < 0 ? styles.negativeStatus : styles.statsStatus} d-inline-flex align-items-center gap-1`}>
                  <span>
                    {dashboardData?.totalCourseLearnersGrowth >= 0 ? (<TrendIcon color="#139a74" />) : (<NegativTrendIcon color="red" />)}
                    {dashboardData?.totalCourseLearnersGrowth}
                    {dashboardData?.totalCourseLearnersGrowth >= 0 ? '+' : '-'}
                  </span>
                </span>
                <span className={`${styles.statsTitle} d-inline-flex`}>Course Learners</span>
              </div>
            </div>
          </div>
          <div className={`${styles.dashboardBlock} col-12 d-inline-flex align-items-center mt-4`}>
            <HighchartsReact
              highcharts={Highcharts}
              options={EarnOptions}
              containerProps={{ style: { width: "100%" } }}
            />
          </div>
          <div className={`col-12 d-inline-flex align-items-start gap-4 mt-4`}>
            <div className={`${styles.dashboardBlock} col-6 flex-shrink-1 d-inline-flex align-items-center`}>
              <HighchartsReact
                highcharts={Highcharts}
                options={TrafficOptions}
                containerProps={{ style: { width: "100%" } }}
              />
            </div>
            <div className={`${styles.dashboardBlock} col-6 flex-shrink-1 d-inline-flex flex-column`}>
              <div className={`${styles.recentTitleRow} col-12 d-inline-flex justify-content-between align-items-center p-3`}>
                <h4 className={`${styles.recentTitle} d-inline-flex mb-0`}>Recent Courses</h4>
                <span role="button" className={`${styles.recentViewBtn} px-3 d-inline-flex align-items-center`}> <Link to="/courses" className={`${styles.orderLink} d-inline-flex text-decoration-none`}>View All</Link></span>
              </div>
              <div className={`col-12 d-inline-flex flex-column align-items-start px-3`}>
                {coursesList?.slice(0, 4)?.map((item, index) =>
                  <div className={`${styles.addedCourseRow} col-12 d-inline-flex align-items-center col-12 gap-3 py-2`} key={index}>
                    <span className={`${styles.courseImag} flex-shrink-0 d-inline-flex align-items-center justify-content-center`}></span>
                    <div className='flex-grow-1 d-flex flex-column'>
                      <h6 className={`${styles.courseName} text-truncate`}>{item?.name}</h6>
                      <div className='col-12 d-inline-flex align-items-center gap-2'>
                        <span className={`${styles.authourImg} flex-shrink-0 d-inline-flex align-items-center justify-content-center`}>{item?.user_name?.charAt(0)}</span>
                        <span className={`${styles.authorName} d-inline-flex`}>{item?.user_name}</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
}