import { axiosInstance } from "../ApiUrls/Interceptors";
import { ApiUrls } from "../ApiUrls/apiUrls";


export const getCoursesSale = async (filters) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_COURSES_SALE_DATA}`,
      {
        params: filters
      });

    return {
      status: true,
      rows: result?.data?.data?.course_sales?.count[0].total,
      data: result?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
};


export const getAllPurchases = async (filters) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_ALL_PURCHASES_DATA}`,
      {
        params: filters
      });

    return {
      status: true,
      rows: result?.data?.data?.all_purchases?.count,
      data: result?.data?.data?.all_purchases?.rows,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
};


export const getBIllingEmployer = async (filters) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_BILLING_EMPLOYER}`,
      {
        params: filters
      });

    return {
      status: true,
      rows: result?.data?.data?.list?.count,
      data: result?.data?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
};


export const getBIllingEmployerDetails = async (id) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_BILLING_EMPLOYER_DETAILS}?subscribedUserId=${id}`);

    return {
      status: true,
      data: result?.data?.data?.details,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}


export const getBIllingEmployerDetailsList = async (id) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_BILLING_EMPLOYER_DETAILS_LIST}?subscribedUserId=${id}`);

    return {
      status: true,
      rows: result?.data?.data?.payments?.count,
      data: result?.data?.data?.payments?.newRows,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}


export const getBIllingEmployerListDetails = async (id) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_BILLING_EMPLOYER_LIST_DETAILS}?order_id=${id}`);

    return {
      status: true,
      data: result?.data?.data?.payments,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}