const formatDateTime = (inputDateString) => {
  const inputDate = new Date(inputDateString);

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'UTC'
  };

  const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(inputDate);

  return formattedDate;
}

export {
  formatDateTime
}



export const MonthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

const FullMonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const WeekNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const getDateYearNumFormat = (date, type) => {
  const newDate = new Date(date);
  let month = MonthNames[newDate.getMonth()];
  var fulldate = '';
  if (type === 1) {
    month = MonthNames[newDate.getMonth()];
    fulldate = newDate.getDate() + ' ' + month;
  } else {
    month = newDate.getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }
    fulldate = newDate.getFullYear() + '-' + month + '-' + newDate.getDate();
  }
  return fulldate;
};

export const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const formatTime = (date) => {
  const newDate = new Date(date);
  var hours = newDate.getHours();
  var minutes = newDate.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

export const formatTimeHh = (date, type) => {
  const newDate = new Date(date);
  var hours = newDate.getHours();
  if (hours < 10) {
    hours = '0' + hours;
  }
  var minutes = newDate.getMinutes();
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  var seconds = newDate.getSeconds();
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  var strTime = '';
  if (type === 1) {
    strTime = hours + ':' + minutes;
  } else {
    strTime = hours + ':' + minutes + ':' + seconds;
  }
  return strTime;
};


export const calculateTimeDifference = (timestamp) => {
  const currentTime = new Date();
  const previousTime = new Date(timestamp);
  const timeDifference = currentTime - previousTime;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) {
    return seconds + ' sec ago';
  } else if (minutes < 60) {
    return minutes + ' min ago';
  } else if (hours < 24) {
    return hours + ' hours ago';
  } else {
    return days + ' days ago';
  }
};