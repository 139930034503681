'use client';
import React, { useEffect, useState } from 'react';
import styles from './login.module.css';
import Logo from '../../assets/images/Aus_Getters_Logo_rectangle.svg';
import LoginBg from '../../assets/images/login_background.png';
import passwordEye from '../../assets/images/password.svg';
import passwordClose from '../../assets/images/password-close.svg';
import unchecked from '../../assets/images/emptyCheckbox.svg';
import checked from '../../assets/images/filledCheckBox.svg';
import { Link } from 'react-router-dom';
import { login } from '../../services/user.service';
import toast from 'react-hot-toast';
import { useAuth } from '../../hook/useAuth';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [loginAgree, setloginAgree] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");


  const agreeloginRember = () => {
    if (loginAgree === true) {
      setloginAgree(false);
    } else {
      setloginAgree(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const credentials = { email, password };
    const result = await login(credentials);

    if (result.status === 200) {
      const access_token = result?.data?.data?.token;
      localStorage.setItem("access_token", access_token);
      setAuth({ ...auth, isLoggedIn: true });
    } else {
      toast.error("Invalid Credentials");
    }
  }

  useEffect(() => {
    if (auth.isLoggedIn) {
      navigate('/');
    }
  }, [auth.isLoggedIn]);

  return (
    !auth.isLoggedIn &&
    <div
      className={`${styles.LoginContainer} col-12 d-inline-flex align-items-stretch`}
    >
      <Link
        to="/"
        className={`${styles.LogoBox} position-absolute d-inline-flex align-items-center gap-2 text-decoration-none`}
      >
        <img src={Logo} className="object-fit-contain" alt="Logo" />
        <span className={`${styles.logoText} d-none text-uppercase`}>
          AUS-GETTERS
        </span>
      </Link>
      <div className="col-md-6">
        <img
          src={LoginBg}
          alt="Login Bg"
          className="object-fit-cover col-12 d-inline-block h-100"
        />
      </div>
      <div className={`${styles.loginFromContainer} col-md-6`}>
        <h1
          className={`mb-5 col-12 justify-content-center d-inline-flex align-items-center`}
        >
          <span className={styles.loginName}>Sign In</span>
          &nbsp;/&nbsp;
          <Link
            href="/get-started"
            className={`text-black ${styles.signLink}`}
          >
            Sign Up
          </Link>
        </h1>
        <form
          className={`${styles.loginFromBox} col-12 d-inline-flex flex-column`}
          onSubmit={handleSubmit}
        >
          <div
            className={`${styles.loginFormFloating} col-12 position-relative d-inline-block`}
          >
            <input
              type="email"
              name="email"
              value={email}
              placeholder="email"
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="off"
              className={`${styles.formInput} d-inline-block col-12`}
            />
            <label
              className={`${styles.formLabel} position-absolute d-inline-flex align-items-center`}
            >
              <span className={styles.required}>*</span> Email Address
            </label>
          </div>
          <div className={`mb-3 col-12 position-relative d-inline-block`}>
            <input
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={password}
              placeholder="password"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="off"
              className={`${styles.formInput} d-inline-block col-12`}
            />
            <label
              className={`${styles.formLabel} position-absolute d-inline-flex align-items-center`}
            >
              <span className={styles.required}>*</span> Password
            </label>
            {!showPassword ? (
              <img
                src={passwordClose}
                role="button"
                alt="Hide Password"
                onClick={() => setShowPassword(!showPassword)} // Toggle showPassword to false
                className={`${styles.passwordEye} position-absolute`}
              />
            ) : (
              <img
                src={passwordEye}
                role="button"
                alt="Show Password"
                onClick={() => setShowPassword(!showPassword)} // Toggle showPassword to true
                className={`${styles.passwordEye} position-absolute`}
              />
            )}
          </div>
          <div className="d-inline-flex align-items-center gap-2" onClick={agreeloginRember}>
            <span className={`${styles.confirmCheck} d-inline-flex`} role="button">
              {loginAgree === false ? (
                <img
                  src={unchecked}
                  alt="unchecked"
                  className="object-fit-contain"
                />
              ) : loginAgree === true ? (
                <img
                  src={checked}
                  alt="checked"
                  className="object-fit-contain"
                />
              ) : (
                ''
              )}
            </span>
            <p className={`${styles.rememberMe} d-inline-flex mb-0`} role="button">Remember Me</p>
          </div>
          <div className='col-12 d-inline-flex justify-content-end mb-4'>
            <Link href="forgot-password" className={`${styles.forgetPassLink} d-inline-flex`}>Forgot Password?</Link>
          </div>
          <button
            type="submit"
            className={`${styles.fromSubmitBtn} col-12 d-inline-block`}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}
