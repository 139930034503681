import React, { useEffect, useState } from 'react';
import styles from './BillingCourse.module.css';
import { Layout } from '../../Components/Layout/Layout';
import { PageHeader } from '../../Components/PageHeader/PageHeader';
import { LearnerIcon, NegativTrendIcon, TrendIcon } from '../../siteicons';
import { TableTabHeader } from '../../Components/TableTabHeader/TableTabHeader';
import { SalesCoursesList } from '../../Components/SalesCoursesList/SalesCoursesList';
import { getCoursesSale } from '../../services/billing.service';
import { formatDate } from '../../utils/DateTimeFormat';

export const BillingCourse = () => {
    const [coursesSaleList, setCoursesSaleList] = useState([])
    const [selectedFilter, setSelectedFilter] = useState({
        pageNumber: 1,
        name: "",
        start_date: formatDate(new Date()),
        end_date: formatDate(new Date()),
    })
    const coursesSaleData = coursesSaleList?.data?.data;

    const getCoursesSaleList = async (data) => {
        const filters = {
            page_no: selectedFilter?.pageNumber,
            course_name: selectedFilter?.name,
            // start_date: selectedFilter?.start_date,
            // end_date: selectedFilter?.end_date,
        }

        // if (activeTab !== "All") {
        //     filters.approved_by_admin = activeTab;
        // }
        const result = await getCoursesSale(filters);

        if (result.status) {
            setCoursesSaleList(result);
        }
    };

    useEffect(() => {
        getCoursesSaleList();
    }, [selectedFilter])

    return (
        <Layout>
            <div className='col-12 d-inline-flex flex-column p-4'>
                <PageHeader
                    title="Courses"
                    setSelectedFilter={setSelectedFilter}
                />
                <div className='col-12 d-inline-flex align-items-stretch gap-4 pt-4'>
                    <div className={`${styles.dashboardBlock} col-3 d-inline-flex flex-column p-3 flex-shrink-1`}>
                        <div className='col-12 d-inline-flex align-items-center justify-content-between mb-3'>
                            <span className={`${styles.blockTitle} d-inline-flex`}>Total Courses Sales</span>
                            <span className={`${styles.blockIconBox} d-inline-flex`}><LearnerIcon color="#5D5FEF" /></span>
                        </div>
                        <h2 className={`${styles.dashboardStats} d-inline-flex col-12`}>{coursesSaleData?.total_course_sale}</h2>
                        <div className='col-12 d-inline-flex align-items-center gap-2'>
                            <span className={`${coursesSaleData?.total_course_sale_growth >= 0 ? styles.statsStatus : coursesSaleData?.total_course_sale_growth < 0 ? styles.negativeStatus : styles.statsStatus} d-inline-flex align-items-center gap-1`}>
                                <span>
                                    {coursesSaleData?.total_course_sale_growth >= 0 ? (<TrendIcon color="#139a74" />) : (<NegativTrendIcon color="red" />)}
                                    {coursesSaleData?.total_course_sale_growth}
                                    {coursesSaleData?.total_course_sale_growth >= 0 ? '+' : '-'}
                                </span>
                            </span>
                            <span className={`${styles.statsTitle} d-inline-flex`}>Number of Sales</span>
                        </div>
                    </div>
                </div>
                <div className='col-12 d-inline-flex flex-column mt-4'>
                    <TableTabHeader
                        searchPlaceholder="Search Courses"
                        setSelectedFilter={setSelectedFilter}
                        onTabClick={(item) => {
                            setSelectedFilter((prev) => ({
                                ...prev,
                                pageNumber: 1
                            }))
                        }}
                    />
                    <SalesCoursesList
                        coursesSaleList={coursesSaleList}
                        setSelectedFilter={setSelectedFilter}
                    />
                </div>
            </div>
        </Layout>
    )
}