import React from 'react';
import styles from './AnalyticsGraph.module.css';
import { TrendIcon } from '../../siteicons';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export const AnalyticsGraph = () => {

    const userChart = {
        chart: {
            type: 'area'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            allowDecimals: false,
            accessibility: {
                rangeDescription: 'Range: 1940 to 2017.'
            },
            labels: {
                enabled: false
            },
            visible: false,
        },
        yAxis: {
            title: {
                text: ''
            },
            labels: {
                enabled: false
            },
            visible: false,
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            area: {
                pointStart: 1940,
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 2,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                }
            }
        },
        series: [{
            name: 'User',
            data: [1703, 2422, 3692, 5543, 7345, 4571, 4018, 3822],
            color: '#a4a6f6'
        }]
    }

    const visitorChart = {
        chart: {
            type: 'area'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            allowDecimals: false,
            accessibility: {
                rangeDescription: 'Range: 1940 to 2017.'
            },
            labels: {
                enabled: false
            },
            visible: false,
        },
        yAxis: {
            title: {
                text: ''
            },
            labels: {
                enabled: false
            },
            visible: false,
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            area: {
                pointStart: 1940,
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 2,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                }
            }
        },
        series: [{
            name: 'UNIQUE VISITORS',
            data: [1703, 4571, 4018, 3822, 2422, 3692, 5543, 7345],
            color: '#c28135'
        }]
    }

    const BounceChart = {
        chart: {
            type: 'area'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            allowDecimals: false,
            accessibility: {
                rangeDescription: 'Range: 1940 to 2017.'
            },
            labels: {
                enabled: false
            },
            visible: false,
        },
        yAxis: {
            title: {
                text: ''
            },
            labels: {
                enabled: false
            },
            visible: false,
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            area: {
                pointStart: 1940,
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 2,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                }
            }
        },
        series: [{
            name: 'BOUNCE RATE',
            data: [7345, 4571, 4018, 3822, 1703, 2422, 3692, 5543],
            color: '#389437'
        }]
    }

    const AvgVisitChart = {
        chart: {
            type: 'area'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            allowDecimals: false,
            accessibility: {
                rangeDescription: 'Range: 1940 to 2017.'
            },
            labels: {
                enabled: false
            },
            visible: false,
        },
        yAxis: {
            title: {
                text: ''
            },
            labels: {
                enabled: false
            },
            visible: false,
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            area: {
                pointStart: 1940,
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 2,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                }
            }
        },
        series: [{
            name: 'AVERAGE VISIT TIME',
            data: [1703, 5543, 4018, 3822, 2422, 3692, 7345, 4571],
            color: '#f178b6'
        }]
    }

    return (
        <React.Fragment>
            <div className='col-12 d-inline-flex align-items-stretch gap-4 pt-4'>
                <div className={`${styles.dashboardBlock} col-3 d-inline-flex p-3 flex-shrink-1`}>
                    <div className='col-6 d-inline-flex flex-column'>
                        <div className='col-12 d-inline-flex align-items-center justify-content-between mb-3'>
                            <span className={`${styles.blockTitle} text-uppercase d-inline-flex`}>USER</span>
                        </div>
                        <h2 className={`${styles.dashboardStats} d-inline-flex col-12`}>$10,800</h2>
                        <div className='col-12 d-inline-flex align-items-center gap-2'>
                            <span className={`${styles.statsStatus} d-inline-flex align-items-center gap-1`}>
                                <TrendIcon color="#139a74" /> $10+
                            </span>
                        </div>
                    </div>
                    <div className='col-6 d-inline-flex flex-column'>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={userChart}
                            containerProps={{ style: { width: "100%", height: '110px' } }}
                        />
                    </div>
                </div>
                <div className={`${styles.dashboardBlock} col-3 d-inline-flex p-3 flex-shrink-1`}>
                    <div className='col-6 d-inline-flex flex-column'>
                        <div className='col-12 d-inline-flex align-items-center justify-content-between mb-3'>
                            <span className={`${styles.blockTitle} text-uppercase d-inline-flex`}>UNIQUE VISITORS</span>
                        </div>
                        <h2 className={`${styles.dashboardStats} d-inline-flex col-12`}>1,22,456</h2>
                        <div className='col-12 d-inline-flex align-items-center gap-2'>
                            <span className={`${styles.statsStatus} d-inline-flex align-items-center gap-1`}>
                                <TrendIcon color="#139a74" /> 120+
                            </span>
                        </div>
                    </div>
                    <div className='col-6 d-inline-flex flex-column'>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={visitorChart}
                            containerProps={{ style: { width: "100%", height: '110px' } }}
                        />
                    </div>
                </div>
                <div className={`${styles.dashboardBlock} col-3 d-inline-flex p-3 flex-shrink-1`}>
                    <div className='col-6 d-inline-flex flex-column'>
                        <div className='col-12 d-inline-flex align-items-center justify-content-between mb-3'>
                            <span className={`${styles.blockTitle} text-uppercase d-inline-flex`}>BOUNCE RATE</span>
                        </div>
                        <h2 className={`${styles.dashboardStats} d-inline-flex col-12`}>22,786</h2>
                        <div className='col-12 d-inline-flex align-items-center gap-2'>
                            <span className={`${styles.statsStatus} d-inline-flex align-items-center gap-1`}>
                                <TrendIcon color="#139a74" /> 56+
                            </span>
                        </div>
                    </div>
                    <div className='col-6 d-inline-flex flex-column'>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={BounceChart}
                            containerProps={{ style: { width: "100%", height: '110px' } }}
                        />
                    </div>
                </div>
                <div className={`${styles.dashboardBlock} col-3 d-inline-flex p-3 flex-shrink-1`}>
                    <div className='col-6 d-inline-flex flex-column'>
                        <div className='col-12 d-inline-flex align-items-center justify-content-between mb-3'>
                            <span className={`${styles.blockTitle} text-uppercase d-inline-flex`}>AVERAGE VISIT TIME</span>
                        </div>
                        <h2 className={`${styles.dashboardStats} d-inline-flex col-12`}>2,456</h2>
                        <div className='col-12 d-inline-flex align-items-center gap-2'>
                            <span className={`${styles.statsStatus} d-inline-flex align-items-center gap-1`}>
                                <TrendIcon color="#139a74" /> 80+
                            </span>
                        </div>
                    </div>
                    <div className='col-6 d-inline-flex flex-column'>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={AvgVisitChart}
                            containerProps={{ style: { width: "100%", height: '110px' } }}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}