const baseUrl = process.env.REACT_APP_APP_FULL_API_URL;

export const ApiUrls = {
  LOGIN: baseUrl + "/admin/users/login",
  GET_PROFILE: baseUrl + "/admin/users/profile",
  UPLOAD_IMAGE: baseUrl + "/upload/image",

  // Users
  GET_USERS: baseUrl + "/user/get-users-list-for-super-admin",
  UPDATE_USERS_STATUS: baseUrl + "/admin/users/change-status",
  GET_USERS_CONST_DATA: baseUrl + "/employer/candidate-list-filter",

  // Dashboard
  GET_DASHBOARD_DAY_LIST: baseUrl + "/courses/get-number-of-days-list",
  GET_DASHBOARD_DATA: baseUrl + "/super-admin/dashboard-data",

  //Category
  GET_CATEGORY_LIST: baseUrl + "/admin/job_category/list",
  CREATE_CATEGORY: baseUrl + "/admin/job_category/add",
  UPDATE_CATEGORY: baseUrl + "/admin/job_category/edit",

  // Jobs
  GET_JOB_LIST: baseUrl + "/job/get-jobs-list-for-super-admin",
  GET_JOB_CONST_DATA: baseUrl + "/user/jobs-list-filter",
  UPDATE_JOB_STATUS: baseUrl + "/admin/job/update-job-status",

  // Courses
  GET_COURSES_LIST: baseUrl + "/courses/get-courses-list-for-super-admin",
  UPDATE_COURSES: baseUrl + "/courses/update-courses-for-admin",
  UPDATE_COURSES_STATUS: baseUrl + "/admin/course/update-course-status",
  ADD_COURSES: baseUrl + "/courses/add-courses-traffic",
  GET_COURSES_CONST_DATA: baseUrl + "/courses/get-course-option",

  // Alert
  GET_ALERT_LIST: baseUrl + "/admin/alerts/list",
  GET_ALERT_CONST_DATA: baseUrl + "/admin/alerts/getAlertData",
  CREATE_ALERT: baseUrl + "/admin/alerts/add",
  DELETE_ALERT: baseUrl + "/admin/alerts/delete",
  GET_ALERT_DETAILS: baseUrl + "/admin/alerts/details",
  UPDATE_ALERT_DETAILS: baseUrl + "/admin/alerts/update",

  //Exclusive Service
  GET_EXCLUSIVE_SERVICE_LIST: baseUrl + "/exclusive-services/list-for-admin",

  // Billing
  GET_COURSES_SALE_DATA: baseUrl + "/courses/get-courses-sale-for-super-admin",
  GET_ALL_PURCHASES_DATA: baseUrl + "/super-admin/all-purchases",
  GET_BILLING_EMPLOYER: baseUrl + "/super-admin/get-subscriptions-list-for-admin",
  GET_BILLING_EMPLOYER_DETAILS: baseUrl + "/super-admin/get-subscriptions-details-for-admin",
  GET_BILLING_EMPLOYER_DETAILS_LIST: baseUrl + "/super-admin/get-subscriptions-recent-payments-for-admin",
  GET_BILLING_EMPLOYER_LIST_DETAILS: baseUrl + "/super-admin/get-subscriptions-payment-order-summary-for-admin",


  // Subscription plan
  GET_SUBSCRIPTION_PLAN_LIST: baseUrl + "/subscription-plan/list",
  ADD_SUBSCRIPTION_PLAN: baseUrl + "/subscription-plan/add-update",
  INACTIVE_SUBSCRIPTION_PLAN: baseUrl + "/subscription-plan/inactive-plan/"
};
