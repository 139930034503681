import { axiosInstance } from "../ApiUrls/Interceptors";
import { ApiUrls } from "../ApiUrls/apiUrls";

/**
 * GET
 * @param {Object} filters 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get all the Courses List 
 */
export const getCoursesList = async (filters) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_COURSES_LIST}`,
      {
        params: filters
      });

    return {
      status: true,
      rows: result?.data?.data?.list?.rows,
      data: result?.data?.data?.list?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
};


/**
 * @method POST
 * @param {Object} data 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Update the Status of courses status
 */
export const updateCourses = async (data) => {
  try {
    const result = await axiosInstance.post(`${ApiUrls.UPDATE_COURSES_STATUS}`, data);

    return {
      status: true,
      data: result?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}

/**
 * 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get all Const Data related to Courses
 */
export const getCoursesConstData = async () => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_COURSES_CONST_DATA}`);

    return {
      status: true,
      data: result?.data?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}