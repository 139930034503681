import React, { useEffect } from 'react';
import styles from './Analytics.module.css';
import { Layout } from '../../Components/Layout/Layout';
import { PageHeader } from '../../Components/PageHeader/PageHeader';
import { ExternalLink, FacebookIcon, GithubIcon, InstagramIcon, LinkedInIcon, TrendIcon, TwitterIcon, YoutubeIcon } from '../../siteicons';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMap from "highcharts/modules/map";
import proj4 from "proj4";
import mapDataAU from "@highcharts/map-collection/countries/au/au-all.geo.json";
import chrome from '../../assets/images/chrome.svg';
import brave from '../../assets/images/brave.svg';
import firefox from '../../assets/images/firefox.svg';
import internetExplore from '../../assets/images/ie.svg';
import safari from '../../assets/images/safari.svg';
import { AnalyticsGraph } from '../../Components/AnalyticsGraph/AnalyticsGraph';

highchartsMap(Highcharts);

export const Analytics = () => {

    const SessionOptions = {
        chart: {
            type: 'spline',
        },
        title: {
            text: '',
            align: 'left'
        },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yAxis: {
            title: {
                text: ''
            },
            labels: {
                format: '{value}K'
            }
        },
        tooltip: {
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            valueDecimals: 2,
            split: true
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'Hestavollane',
            data: [5.4, 5.2, 5.7, 6.3, 5.2, 5.6, 6.1,5.6, 5.9, 7.1],
            dashStyle: 'ShortDashDot',
        }, {
            name: 'VoiceOver',
            data: [20.2, 30.7, 36.8, 30.9, 39.6, 47.1],
            dashStyle: 'ShortDot'
        }, {
            name: 'ZoomText/Fusion',
            data: [6.1, 6.8, 5.3, 27.5, 6.0, 5.5],
            dashStyle: 'ShortDot'
        }],
        navigation: {
            menuItemStyle: {
                fontSize: '10px'
            }
        }
    }

    const ActiveUsers = {
        chart: {
            type: 'column'
        },
        title:{
            text:''
        },
        xAxis: {
            categories: ['USA', 'China', 'Brazil', 'EU', 'India', 'Russia'],
            crosshair: true,
            accessibility: {
                description: 'Countries'
            },
            labels: {
                enabled: false
            },
            visible: false,
        },
        yAxis: {
            min: 0,
            labels: {
                enabled: false
            },
            visible: false,
        },
        tooltip: {
            valueSuffix: ' (1000 MT)'
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        series: [
            {
                name: 'Corn',
                groupPadding: 0,
                data: [755, 222, 151, 600, 350, 400, 500, 300, 250, 200, 151, 86, 400, 100, 750, 300]
            }
        ]
    }

    const TrafficOptions = {
        title: {
            text: '',
        },
        xAxis: {
            labels: {
                enabled: false
            },
            visible: false,
        },
        yAxis: {
            labels: {
                enabled: false
            },
            visible: false,
        },
        legend: {
            enabled: false
        },
        tooltip: {
            valueDecimals: 2,
            valueSuffix: ' TWh'
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                colorByPoint: true,
                type: 'pie',
                size: '100%',
                innerSize: '50%',
                dataLabels: {
                    enabled: true,
                    crop: false,
                    distance: '-10%',
                    style: {
                        fontWeight: 'bold',
                        fontSize: '16px'
                    },
                    connectorWidth: 0
                }
            }
        },
        colors: ['#B8E8FC', '#BCE29E', '#f178b6', '#5d5fef'],
        series: [
            {
            type: 'pie',
            name: 'Percentage',
            data: [
                {
                    name: 'Organic Search',
                    y: 55.02
                },
                {
                    name: 'Direct',
                    y: 15.5
                },
                {
                    name: 'Social Media',
                    y: 45.5
                }
            ]
            }
        ]
    }

    const geojson = {
        chart: {
            map: 'countries/au/au-all'
        },
        title: {
            text: ''
        },
        xAxis: {
            labels: {
                enabled: false
            },
            visible: false,
        },
        yAxis: {
            labels: {
                enabled: false
            },
            visible: false,
        },
        legend: {
            enabled: false
        },
        tooltip: {
            valueDecimals: 2,
            valueSuffix: ' TWh'
        },
        credits: {
            enabled: false
        },
        accessibility: {
            typeDescription: 'Map of Germany.'
        },
        mapNavigation: {
            enabled: true,
            buttonOptions: {
                verticalAlign: 'bottom'
            }
        },
        colorAxis: {
            tickPixelInterval: 100
        },
        series: [{
            data: [
                ['au-nt', 10], ['au-wa', 11], ['au-act', 12], ['au-sa', 13], ['au-qld', 14], ['au-nf', 15], ['au-tas', 16], ['au-jb', 17], ['au-nsw', 18], ['au-vic', 19]
            ],
            name: 'Random data',
            mapData: mapDataAU,
            states: {
                hover: {
                    color: '#BADA55'
                }
            },
            dataLabels: {
                enabled: true,
                format: '{point.properties.postal}'
            }
        }]
    }

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.proj4 = window.proj4 || proj4;
        }
    }, []);

    return (
        <Layout nav="Analytics">
            <div className='col-12 d-inline-flex flex-column p-4'>
                <PageHeader title="Analytics" />
                <AnalyticsGraph />
                <div className={`col-12 d-inline-flex align-items-stretch mt-4 gap-4`}>
                    <div className={`${styles.dashboardBlock} col-8 d-inline-flex flex-column flex-shrink-1`}>
                        <h4 className={`${styles.cardTitle} col-12 d-inline-flex p-3 px-4`}>Sessions</h4>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={SessionOptions}
                            containerProps={{ style: { width: "100%", height: '100%' } }}
                        />
                    </div>
                    <div className={`${styles.dashboardBlock} col-4 d-inline-flex flex-column flex-shrink-1`}>
                        <h4 className={`${styles.cardTitle} col-12 d-inline-flex p-3 px-4`}>Active User</h4>
                        <div className='col-12 d-inline-flex align-items-stretch gap-3 px-3 mt-3'>
                            <div className='col-4 d-inline-flex flex-column'>
                                <h6 className={`${styles.statsLabel} col-12 d-inline-flex mb-2`}>30 days</h6>
                                <h2 className={`${styles.statesCount} col-12 d-inline-flex mb-0`}>12,698</h2>
                                <span className={`${styles.statsStatus} d-inline-flex align-items-center gap-1`}>
                                    <TrendIcon color="#139a74" /> 8.0%
                                </span>
                            </div>
                            <div className='col-4 d-inline-flex flex-column'>
                                <h6 className={`${styles.statsLabel} col-12 d-inline-flex mb-2`}>7 days</h6>
                                <h2 className={`${styles.statesCount} col-12 d-inline-flex mb-0`}>2.65K</h2>
                                <span className={`${styles.statsStatus} d-inline-flex align-items-center gap-1`}>
                                    <TrendIcon color="#139a74" /> 3.2%
                                </span>
                            </div>
                            <div className='col-4 d-inline-flex flex-column'>
                                <h6 className={`${styles.statsLabel} col-12 d-inline-flex mb-2`}>1 days</h6>
                                <h2 className={`${styles.statesCount} col-12 d-inline-flex mb-0`}>1.34K</h2>
                                <span className={`${styles.statsStatus} d-inline-flex align-items-center gap-1`}>
                                    <TrendIcon color="#139a74" /> 4.6%
                                </span>
                            </div>
                        </div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={ActiveUsers}
                            containerProps={{ style: { width: "100%" } }}
                        />
                    </div>
                </div>
                <div className={`col-12 d-inline-flex align-items-stretch mt-4 gap-4`}>
                    <div className={`${styles.dashboardBlock} col-4 d-inline-flex flex-column flex-shrink-1`}>
                        <h4 className={`${styles.cardTitle} col-12 d-inline-flex p-3 px-4`}>User by States</h4>
                        {geojson !== '' &&
                            <HighchartsReact
                                constructorType ={'mapChart'}
                                highcharts={Highcharts}
                                options={geojson}
                                containerProps={{ style: { width: "100%" } }}
                            />
                        }
                        <div className='col-12 d-inline-flex flex-column px-3'>
                            <table className={`${styles.tableStates} table table-sm table-borderless`}>
                                <tbody>
                                    <tr>
                                        <td>Queensland</td>
                                        <td>22,120</td>
                                        <td>34.54%</td>
                                    </tr>
                                    <tr>
                                        <td>New South Wales</td>
                                        <td>12,756</td>
                                        <td>22.43%</td>
                                    </tr>
                                    <tr>
                                        <td>Victoria</td>
                                        <td>8,864</td>
                                        <td>34.54%</td>
                                    </tr>
                                    <tr>
                                        <td>South Australia</td>
                                        <td>6,749</td>
                                        <td>5.29%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={`${styles.dashboardBlock} col-4 d-inline-flex flex-column flex-shrink-1`}>
                        <h4 className={`${styles.cardTitle} col-12 d-inline-flex p-3 px-4`}>Traffic Channel</h4>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={TrafficOptions}
                            containerProps={{ style: { width: "100%" } }}
                        />
                    </div>
                    <div className={`${styles.dashboardBlock} col-4 d-inline-flex flex-column flex-shrink-1`}>
                        <h4 className={`${styles.cardTitle} col-12 d-inline-flex p-3 px-4`}>Live Users</h4>
                        <h2 className={`${styles.liveUserCount} col-12 d-inline-flex justify-content-center p-3 m-auto px-4`}>7,52,892</h2>
                    </div>
                </div>
                <div className={`col-12 d-inline-flex align-items-stretch mt-4 gap-4 mb-5`}>
                    <div className={`${styles.dashboardBlock} col-4 d-inline-flex flex-column flex-shrink-1`}>
                        <h4 className={`${styles.cardTitle} col-12 d-inline-flex p-3 px-4`}>Browsers</h4>
                        <div className='col-12 d-inline-flex flex-column px-3'>
                            <table className={`${styles.tableStates} table table-sm table-borderless`}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className={`${styles.browserIcon} d-inline-flex align-items-center gap-2`}>
                                                <img src={chrome} alt="chrome" className='object-fit-contain' />
                                                <span className={`${styles.browserName} d-inline-flex`}>Google Chrome</span>
                                            </div>
                                        </td>
                                        <td>34.54%</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${styles.browserIcon} d-inline-flex align-items-center gap-2`}>
                                                <img src={brave} alt="brave" className='object-fit-contain' />
                                                <span className={`${styles.browserName} d-inline-flex`}>Google Chrome</span>
                                            </div>
                                        </td>
                                        <td>22.43%</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${styles.browserIcon} d-inline-flex align-items-center gap-2`}>
                                                <img src={safari} alt="safari" className='object-fit-contain' />
                                                <span className={`${styles.browserName} d-inline-flex`}>Google Chrome</span>
                                            </div>
                                        </td>
                                        <td>34.54%</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${styles.browserIcon} d-inline-flex align-items-center gap-2`}>
                                                <img src={firefox} alt="firefox" className='object-fit-contain' />
                                                <span className={`${styles.browserName} d-inline-flex`}>Google Chrome</span>
                                            </div>
                                        </td>
                                        <td>5.29%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={`${styles.dashboardBlock} col-4 d-inline-flex flex-column flex-shrink-1`}>
                        <h4 className={`${styles.cardTitle} col-12 d-inline-flex p-3 px-4`}>Social Media Traffic</h4>
                        <div className='col-12 d-inline-flex flex-column px-3'>
                            <table className={`${styles.tableStates} table table-sm table-borderless`}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className={`${styles.browserIcon} d-inline-flex align-items-center gap-2`}>
                                                <FacebookIcon color="#605fee" />
                                                <span className={`${styles.browserName} d-inline-flex`}>Facebook</span>
                                            </div>
                                        </td>
                                        <td>75%</td>
                                        <td className={`${styles.progressBox}`}>
                                            <div className={`${styles.progressBoxContainer} d-inline-flex position-relative overflow-hiddden flex-shrink-0 col-12`}>
                                                <div className={`${styles.fbProgressBar} position-absolute h-100 start-0 top-0`} style={{width: '75%'}}></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${styles.browserIcon} d-inline-flex align-items-center gap-2`}>
                                                <InstagramIcon color="#605fee" />
                                                <span className={`${styles.browserName} d-inline-flex`}>Instagram</span>
                                            </div>
                                        </td>
                                        <td>65%</td>
                                        <td className={`${styles.progressBox}`}>
                                            <div className={`${styles.progressBoxContainer} d-inline-flex position-relative overflow-hiddden flex-shrink-0 col-12`}>
                                                <div className={`${styles.itProgressBar} position-absolute h-100 start-0 top-0`} style={{width: '65%'}}></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${styles.browserIcon} d-inline-flex align-items-center gap-2`}>
                                                <TwitterIcon color="#605fee" />
                                                <span className={`${styles.browserName} d-inline-flex`}>Twitter</span>
                                            </div>
                                        </td>
                                        <td>34%</td>
                                        <td className={`${styles.progressBox}`}>
                                            <div className={`${styles.progressBoxContainer} d-inline-flex position-relative overflow-hiddden flex-shrink-0 col-12`}>
                                                <div className={`${styles.twProgressBar} position-absolute h-100 start-0 top-0`} style={{width: '35%'}}></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${styles.browserIcon} d-inline-flex align-items-center gap-2`}>
                                                <LinkedInIcon color="#605fee" />
                                                <span className={`${styles.browserName} d-inline-flex`}>LinkedIn</span>
                                            </div>
                                        </td>
                                        <td>54%</td>
                                        <td className={`${styles.progressBox}`}>
                                            <div className={`${styles.progressBoxContainer} d-inline-flex position-relative overflow-hiddden flex-shrink-0 col-12`}>
                                                <div className={`${styles.lkProgressBar} position-absolute h-100 start-0 top-0`} style={{width: '54%'}}></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${styles.browserIcon} d-inline-flex align-items-center gap-2`}>
                                                <GithubIcon color="#605fee" />
                                                <span className={`${styles.browserName} d-inline-flex`}>Github</span>
                                            </div>
                                        </td>
                                        <td>89%</td>
                                        <td className={`${styles.progressBox}`}>
                                            <div className={`${styles.progressBoxContainer} d-inline-flex position-relative overflow-hiddden flex-shrink-0 col-12`}>
                                                <div className={`${styles.ghProgressBar} position-absolute h-100 start-0 top-0`} style={{width: '89%'}}></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${styles.browserIcon} d-inline-flex align-items-center gap-2`}>
                                                <YoutubeIcon color="#605fee" />
                                                <span className={`${styles.browserName} d-inline-flex`}>Youtube	</span>
                                            </div>
                                        </td>
                                        <td>46%</td>
                                        <td className={`${styles.progressBox}`}>
                                            <div className={`${styles.progressBoxContainer} d-inline-flex position-relative overflow-hiddden flex-shrink-0 col-12`}>
                                                <div className={`${styles.ytProgressBar} position-absolute h-100 start-0 top-0`} style={{width: '46%'}}></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={`${styles.dashboardBlock} col-4 d-inline-flex flex-column flex-shrink-1`}>
                        <h4 className={`${styles.cardTitle} col-12 d-inline-flex p-3 px-4 mb-0`}>Most View Pages</h4>
                        <div className='col-12 d-inline-flex flex-column'>
                            <table className={`${styles.viewPageStates} table table-sm table-borderless`}>
                                <thead className="table-light">
                                    <tr>
                                        <th scope="col">Page</th>
                                        <th scope="col">Exits</th>
                                        <th scope="col">Views</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className={`${styles.browserIcon} d-inline-flex align-items-center gap-2`}>
                                                <span className={`${styles.browserName} d-inline-flex`}>/landings/courses.htm</span>
                                                <ExternalLink color="#1e293b" />
                                            </div>
                                        </td>
                                        <td>75%</td>
                                        <td>1243</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${styles.browserIcon} d-inline-flex align-items-center gap-2`}>
                                                <span className={`${styles.browserName} d-inline-flex`}>landings/lead.html</span>
                                                <ExternalLink color="#1e293b" />
                                            </div>
                                        </td>
                                        <td>65%</td>
                                        <td>943</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${styles.browserIcon} d-inline-flex align-items-center gap-2`}>
                                                <span className={`${styles.browserName} d-inline-flex`}>/checkout.html</span>
                                                <ExternalLink color="#1e293b" />
                                            </div>
                                        </td>
                                        <td>34%</td>
                                        <td>856</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${styles.browserIcon} d-inline-flex align-items-center gap-2`}>
                                                <span className={`${styles.browserName} d-inline-flex`}>/blog.html</span>
                                                <ExternalLink color="#1e293b" />
                                            </div>
                                        </td>
                                        <td>54%</td>
                                        <td>316</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${styles.browserIcon} d-inline-flex align-items-center gap-2`}>
                                                <span className={`${styles.browserName} d-inline-flex`}>/dashboard.html </span>
                                                <ExternalLink color="#1e293b" />
                                            </div>
                                        </td>
                                        <td>89%</td>
                                        <td>235</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}