import React from 'react';
import styles from './SalesCoursesList.module.css';
import CommonTable from '../CommonTable/CommonTable';
import { formatDate, formatTime } from '../../utils/DateTimeFormat';
import CustomPagination from '../../common/CustomPagination';

export const SalesCoursesList = ({ coursesSaleList, setSelectedFilter }) => {
    const coursesSaleData = coursesSaleList?.data?.data?.course_sales;

    const columns = [
        {
            name: 'Courses',
            id: 'courses',
            sortable: false,
            span: 2,
            renderer: (courses) => (
                <div className={`col-12 d-inline-flex align-items-center col-12 gap-3`}>
                    <span className={`${styles.courseImag} flex-shrink-0 d-inline-flex align-items-center justify-content-center`}>{courses?.course_name?.charAt(0)}</span>
                    <div className='flex-grow-1 d-flex flex-column'>
                        <h6 className={`${styles.courseName} col-12 text-truncate`}>{courses?.course_name}</h6>
                        <span className={`${styles.courseTiming} d-inline-flex col-12`}>{formatDate(courses?.course_added_on) + " " + formatTime(courses?.course_added_on)}</span>
                    </div>
                </div>
            )
        },
        {
            name: 'Instructor',
            id: 'Instructor',
            renderer: (instructor) => (
                <div className='col-12 d-inline-flex align-items-center gap-2'>
                    <span className={`${styles.authourImg} flex-shrink-0 d-inline-flex align-items-center justify-content-center`}>{instructor?.instructor_name?.charAt(0)}</span>
                    <span className={`${styles.authorName} d-inline-flex`}>{instructor?.instructor_name}</span>
                </div>
            )
        },
        {
            name: 'Orders',
            id: 'Orders',
            renderer: (orders) => (
                <span className={`${styles.tableData}`}>{orders?.total_orders}</span>
            )
        },
        {
            name: 'Total Spent',
            id: 'TotalSpent',
            renderer: (spent) => (
                <span className={`${styles.tableData}`}>{spent?.total_spent}</span>
            )
        }
    ];

    return (
        <React.Fragment>
            <div className={`${styles.courseListContainer} d-inline-flex flex-column overflow-hidden`}>
                <CommonTable cols={columns} rows={coursesSaleData?.rows} />
                <div className='px-4'>
                    <CustomPagination
                        totalCount={coursesSaleData?.count[0]?.total}
                        onChange={(n) => {
                            setSelectedFilter((prev) => ({
                                ...prev,
                                pageNumber: n
                            }))
                        }}
                    // activePage={1}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}