import React, { useEffect, useState } from 'react';
import styles from './OrderSummery.module.css';
import { PageHeader } from '../../Components/PageHeader/PageHeader';
import { Layout } from '../../Components/Layout/Layout';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { getBIllingEmployerListDetails } from '../../services/billing.service';
import { formatDate } from '../../utils/DateTimeFormat';

export const OrderSummery = () => {
    const { orderId } = useParams();
    const [bIllingEmployerListDetail, setBIllingEmployerListDetail] = useState({});

    const getBIllingEmployerListDetail = async () => {
        const result = await getBIllingEmployerListDetails(orderId);

        if (result.status) {
            setBIllingEmployerListDetail(result?.data)
        }
    }

    useEffect(() => {
        getBIllingEmployerListDetail()
    }, [orderId])

    return (
        <React.Fragment>
            <Layout>
                <div className='col-12 d-inline-flex flex-column p-4'>
                    <PageHeader title="Order Summary" />
                    <div className='col-12 d-inline-flex justify-content-center'>
                        <div className='col-7 d-flex m-auto'>
                            <div className={`${styles.orderProductBox} col-12 d-inline-flex flex-column p-4 gap-3 mt-5`}>
                                <div className={`${styles.orderProductHeader} col-12 d-inline-flex flex-column`}>
                                    <h2 className={`${styles.thankYoutTitle} col-12 mb-4 d-inline-flex`}>Thank you for your order</h2>
                                    <h4 className={`${styles.orderIdRow} col-12 d-inline-flex mb-4`}>Order ID&nbsp;<span className={`${styles.orderTrackId}`}> {bIllingEmployerListDetail?.orderId}</span></h4>
                                </div>
                                <div className={`${styles.orderProductContainer} d-inline-flex flex-column col-12 gap-4 pb-3`}>
                                    <div className={`${styles.orderProductRow} d-inline-flex col-12 gap-5`}>
                                        <div className={`col-12 d-inline-flex align-items-center flex-shrink-1 gap-3`}>
                                            <span className={`${styles.courseImag} flex-shrink-0 d-inline-flex align-items-center justify-content-center`}>R</span>
                                            <div className='flex-grow-1 d-flex flex-column'>
                                                <h6 className={`${styles.courseName} col-12 text-truncate`}>Revolutionize how you build the web</h6>
                                                <div className='col-12 d-inline-flex align-items-center gap-2 mb-2'>
                                                    <span className={`${styles.authourImg} flex-shrink-0 d-inline-flex align-items-center justify-content-center`}>{bIllingEmployerListDetail?.user_name?.charAt(0)}</span>
                                                    <span className={`${styles.authorName} d-inline-flex`}>{bIllingEmployerListDetail?.user_name}</span>
                                                </div>
                                                <span className={`${styles.courseTiming} d-inline-flex col-12`}>Added on {formatDate(bIllingEmployerListDetail?.createdAt)}</span>
                                            </div>
                                        </div>
                                        <span className={`${styles.coursePrice} d-inline-flex flex-shrink-0`}>$49.00</span>
                                    </div>
                                    {/* <div className={`${styles.orderProductRow} d-inline-flex col-12 gap-5`}>
                                        <div className={`col-12 d-inline-flex align-items-center flex-shrink-1 gap-3`}>
                                            <span className={`${styles.courseImag} flex-shrink-0 d-inline-flex align-items-center justify-content-center`}>R</span>
                                            <div className='flex-grow-1 d-flex flex-column'>
                                                <h6 className={`${styles.courseName} col-12 text-truncate`}>Revolutionize how you build the web</h6>
                                                <div className='col-12 d-inline-flex align-items-center gap-2 mb-2'>
                                                    <span className={`${styles.authourImg} flex-shrink-0 d-inline-flex align-items-center justify-content-center`}>J</span>
                                                    <span className={`${styles.authorName} d-inline-flex`}>Jenny Wilson</span>
                                                </div>
                                                <span className={`${styles.courseTiming} d-inline-flex col-12`}>Added on 7 July, 2020</span>
                                            </div>
                                        </div>
                                        <span className={`${styles.coursePrice} d-inline-flex flex-shrink-0`}>$49.00</span>
                                    </div> */}
                                </div>
                                <div className='col-12 d-inline-flex flex-column gap-2'>
                                    <div className='col-12 d-inline-flex justify-content-between'>
                                        <span className={`${styles.priceLabel} d-inline-flex`}>Subtotal</span>
                                        <span className={`${styles.priceValue} d-inline-flex`}>${bIllingEmployerListDetail?.subTotal === null ? "0" : bIllingEmployerListDetail?.subTotal}</span>
                                    </div>
                                    <div className='col-12 d-inline-flex justify-content-between'>
                                        <span className={`${styles.priceLabel} d-inline-flex`}>Shipping</span>
                                        <span className={`${styles.priceValue} d-inline-flex`}>$0</span>
                                    </div>
                                    <div className='col-12 d-inline-flex justify-content-between'>
                                        <span className={`${styles.priceLabel} d-inline-flex`}>Discount</span>
                                        <span className={`${styles.priceValue} d-inline-flex`}>${bIllingEmployerListDetail?.discount}</span>
                                    </div>
                                    <div className='col-12 d-inline-flex justify-content-between'>
                                        <span className={`${styles.priceLabel} d-inline-flex`}>Tax</span>
                                        <span className={`${styles.priceValue} d-inline-flex`}>${bIllingEmployerListDetail?.tax}</span>
                                    </div>
                                    <div className={`${styles.grandRow} col-12 d-inline-flex justify-content-between mt-2 pt-2`}>
                                        <span className={`${styles.priceLabel} d-inline-flex`}>Grand Total</span>
                                        <span className={`${styles.priceValue} d-inline-flex`}>${bIllingEmployerListDetail?.grandTotal}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </React.Fragment>
    )
}