import React, { useEffect, useRef } from 'react';

const OutsideClickHandler = ({ children, onOutsideClick }) => {
    const wrapperRef = useRef(null);
    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            onOutsideClick(event);
        }
    };

    useEffect(() => {
        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);
        // Cleanup function to remove the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideClickHandler