import React from 'react';
import styles from './CustomerHeader.module.css';
import { CalenderIcon, EmailIcon, LocationIcon } from '../../siteicons'
import { formatDate } from '../../utils/DateTimeFormat';

export const CustomerHeader = ({ bIllingEmployerDetailList }) => {
    return (
        <React.Fragment>
            <div className='col-12 d-inline-flex align-items-stretch gap-4'>
                <div className={`${styles.customerInfo} col-8 d-inline-flex flex-shrink-1 flex-column`}>
                    <div className={`${styles.customerContainer} col-12 d-inline-flex align-items-center gap-3 p-4`}>
                        <span className={`${styles.userInitalImg} d-inline-flex align-items-center justify-content-center`}>{bIllingEmployerDetailList?.subscriberName?.charAt(0)}</span>
                        <div className='flex-grow-1 d-inline-flex flex-column gap-2'>
                            <label className={`${styles.userName} d-inline-flex`}>{bIllingEmployerDetailList?.subscriberName}</label>
                            <div className='col-12 d-inline-flex align-items-center gap-4'>
                                <div className={`${styles.userJoined} d-inline-flex align-items-center gap-2`}>
                                    <CalenderIcon color="#555" />
                                    <span className={`${styles.joinedDate}`}>Customer since {formatDate(bIllingEmployerDetailList?.accountCreatedAt)}</span>
                                </div>
                                <div className={`${styles.userJoined} d-inline-flex align-items-center gap-2`}>
                                    <LocationIcon color="#555" />
                                    <span className={`${styles.joinedDate}`}>{bIllingEmployerDetailList?.location}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 d-inline-flex align-items-center justify-content-between gap-3 p-4">
                        <div className='d-inline-flex flex-column'>
                            <span className={`${styles.recentInfoLabel} col-12 d-inline-flex mb-2`}>Last Order</span>
                            <h2 className={`${styles.recentDataStats} col-12 d-inline-flex mb-0`}>20 Hours ago</h2>
                            <span className={`${styles.recentSubLabel} col-12 d-inline-flex`}>White Adidas Low-Top Sneakers</span>
                        </div>
                        <div className='d-inline-flex flex-column'>
                            <span className={`${styles.recentInfoLabel} col-12 d-inline-flex mb-2`}>Lifetime Spent</span>
                            <h2 className={`${styles.recentDataStats} col-12 d-inline-flex mb-0`}>$12,487.00</h2>
                            <span className={`${styles.recentSubLabel} col-12 d-inline-flex`}>Total 18 order</span>
                        </div>
                        <div className='d-inline-flex flex-column'>
                            <span className={`${styles.recentInfoLabel} col-12 d-inline-flex mb-2`}>Average Order</span>
                            <h2 className={`${styles.recentDataStats} col-12 d-inline-flex mb-0`}>$210.18</h2>
                            <span className={`${styles.recentSubLabel} col-12 d-inline-flex`}>$2000.00 Large Order</span>
                        </div>
                    </div>
                </div>
                <div className={`${styles.customerContact} col-4 flex-shrink-1 d-inline-flex align-items-start justify-content-center p-4 gap-4 flex-column`}>
                    <h2 className={`${styles.contactLabel} col-12 d-inline-flex mb-0`}>Contact</h2>
                    <span className={`${styles.contactEmailValue} col-12 d-inline-flex align-items-center gap-2`}><EmailIcon color="#555" />{bIllingEmployerDetailList?.subscriberEmail}</span>
                </div>
            </div>
        </React.Fragment>
    )
}