import React, { useEffect, useState } from 'react';
import { Layout } from '../../Components/Layout/Layout';
import { PageHeader } from '../../Components/PageHeader/PageHeader';
import { TableTabHeader } from '../../Components/TableTabHeader/TableTabHeader';
import { JobList } from '../../Components/JobList/JobList';
import toast from 'react-hot-toast';
import { getJobConstData, getJobList } from '../../services/job.service';

export const Jobs = () => {
    const courseTab = ['All', 'Approved', 'Pending', "Rejected"];
    const orders = [
        { value: 'most_recent', label: 'Most Recent' },
        { value: 'most_past', label: 'Most past' }
    ]
    const [jobList, setJobList] = useState();
    const [jobStstus, setJobStstus] = useState([]);
    const [activeTab, setActiveTab] = useState("All");
    const [jobSearchDateType, setJobSearchDateType] = useState([])
    const [selectedFilter, setSelectedFilter] = useState({
        pageNumber: 1,
        date_range: "all",
        title: "",
        order: "most_recent"
    })

    const FilterData = Object.entries(jobSearchDateType).map(([value, label]) => ({
        value,
        label
    }));

    const jobStstusData = Object.entries(jobStstus).map(([value, label]) => ({
        value,
        label
    }));

    const getCourseSearchDateType = async () => {
        const result = await getJobConstData();
        if (result.status) {
            setJobStstus(result?.data?.jobStatuses)
            setJobSearchDateType(result?.data?.jobDateRangeTypes);
        } else {
            toast.error(result?.error);
        }
    }

    const getJobLIsts = async () => {
        const filters = {
            page_no: selectedFilter?.pageNumber,
            date_range: selectedFilter?.date_range,
            title: selectedFilter?.name,
            order: selectedFilter?.order,
        }
        if (activeTab !== "All") {
            filters.approved_by_admin = activeTab
        }
        const result = await getJobList(filters);
        if (result.status) {
            setJobList(result);
        }
    }

    useEffect(() => {
        getJobLIsts()
        getCourseSearchDateType()
    }, [activeTab, selectedFilter])

    return (
        <Layout>
            <div className='col-12 d-inline-flex flex-column pt-4 px-4'>
                <PageHeader
                    title="Jobs"
                    type="filter"
                    filterDateType={FilterData}
                    selectedFilter={selectedFilter}
                    setSelectedFilter={setSelectedFilter}
                />
                <div className='col-12 d-inline-flex flex-column mt-4'>
                    <TableTabHeader
                        courseTab={courseTab}
                        searchPlaceholder="Search Jobs"
                        setSelectedFilter={setSelectedFilter}
                        activeTab={activeTab}
                        onTabClick={(item) => {
                            setActiveTab(item);
                            setSelectedFilter((prev) => ({
                                ...prev,
                                pageNumber: 1
                            }))
                        }}
                        selectedFilter={selectedFilter}
                        orders={orders}
                        type={"order"}
                    />
                    <JobList
                        jobList={jobList}
                        getJobLIsts={getJobLIsts}
                        setActiveTab={setActiveTab}
                        setSelectedFilter={setSelectedFilter}
                        jobStstusData={jobStstusData}
                    />
                </div>
            </div>
        </Layout>
    )
}