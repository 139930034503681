import React from 'react';
import styles from './EmployerList.module.css';
import { LocationIcon } from '../../siteicons';
import { Link } from 'react-router-dom';
import CommonTable from '../CommonTable/CommonTable';
import CustomPagination from '../../common/CustomPagination';

export const EmployerList = (props) => {
    const { bIllingEmployerList, setSelectedFilter } = props;
    const tableData = bIllingEmployerList?.data?.list?.rows;

    const columns = [
        {
            name: 'Name',
            id: 'employer',
            sortable: false,
            span: 2,
            renderer: (employer) => (
                <Link to={`/customer-detail/${employer?.subscribedUserId}`} className='col-12 d-inline-flex align-items-center gap-2 text-decoration-none'>
                    <span className={`${styles.authourImg} flex-shrink-0 d-inline-flex align-items-center justify-content-center`}>{employer?.subscriberName?.charAt(0)}</span>
                    <span className={`${styles.authorName} d-inline-flex`}>{employer.subscriberName}</span>
                </Link>
            )
        },
        {
            name: 'Location',
            id: 'location',
            renderer: (location) => (
                <div className={`${styles.locationBox} col-12 d-inline-flex align-items-center gap-2`}>
                    <LocationIcon color="#111" />
                    <span className={`${styles.authorName} d-inline-flex`}>{location?.location}</span>
                </div>
            )
        },
        {
            name: 'Orders',
            id: 'orders',
            renderer: (orders) => (
                <span className={`${styles.tableData}`}>{orders?.order === null ? "0" : orders?.order}</span>
            )
        },
        {
            name: 'Total Spent',
            id: 'spent',
            renderer: (spent) => (
                <span className={`${styles.tableData}`}>${spent?.totalSpent === null ? "0" : spent?.totalSpent}</span>
            )
        },
        {
            name: 'Action',
            id: 'action',
            renderer: (status) => (
                <div className='col-12 d-inline-flex align-items-center gap-2'>
                    <React.Fragment>
                        <span role='button' className={`${styles.rejectBtn} d-inline-flex align-items-center justify-content-center px-3 py-1 border border-dark rounded`} >Delete</span>
                    </React.Fragment>
                </div>
            )
        }
    ];

    return (
        <React.Fragment>
            <div className={`${styles.courseListContainer} d-inline-flex flex-column overflow-hidden`}>
                <CommonTable cols={columns} rows={tableData} />
                <div className='px-4'>
                    <CustomPagination
                        totalCount={bIllingEmployerList?.rows}
                        onChange={(n) => {
                            setSelectedFilter((prev) => ({
                                ...prev,
                                pageNumber: n
                            }))
                        }}
                    // activePage={1}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}