import { axiosInstance } from "../ApiUrls/Interceptors";
import { ApiUrls } from "../ApiUrls/apiUrls";

/**
 * 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get all Const Data related to Dashboard
 */
export const getDayList = async () => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_DASHBOARD_DAY_LIST}`);

    return {
      status: true,
      data: result?.data?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}

/**
 * 
 * @param {Object} filters 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get all Dashboard Data
 */
export const getDashboardData = async (filters) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_DASHBOARD_DATA}`,
      {
        params: filters
      });

    return {
      status: true,
      data: result?.data?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}

/**
 * 
 * @param {Object} filters 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get allExclusive Service Data
 */
export const getExclusiveServiceList = async (filters) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_EXCLUSIVE_SERVICE_LIST}`,
      {
        params: filters
      });
    return {
      status: true,
      rows: result?.data?.data?.rows,
      data: result?.data?.data?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}