import React, { useEffect, useState } from 'react';
import { Layout } from '../../Components/Layout/Layout';
import { PageHeader } from '../../Components/PageHeader/PageHeader';
import { CourseList } from '../../Components/CourseList/CourseList';
import { TableTabHeader } from '../../Components/TableTabHeader/TableTabHeader';
import { getCoursesConstData, getCoursesList } from '../../services/courses.service';
import toast from 'react-hot-toast';


export const Courses = () => {
  const courseTab = ['All', 'Approved', 'Pending', "Rejected"];
  const [coursesList, setCoursesList] = useState([])
  const [activeTab, setActiveTab] = useState("All");
  const [courseSearchDateType, setCourseSearchDateType] = useState()
  const [selectedFilter, setSelectedFilter] = useState({
    pageNumber: 1,
    date_range: "show_all",
    name: "",
  })

  const getCourseSearchDateType = async () => {
    const result = await getCoursesConstData();
    if (result.status) {
      setCourseSearchDateType(result?.data?.courseSearchDateType);
    } else {
      toast.error(result?.error);
    }
  }

  const getAllCoursesList = async (data) => {
    const filters = {
      page_no: selectedFilter?.pageNumber,
      date_range: selectedFilter?.date_range,
      name: selectedFilter?.name,
    }

    if (activeTab !== "All") {
      filters.approved_by_admin = activeTab;
    }
    const result = await getCoursesList(filters);

    if (result.status) {
      setCoursesList(result);
    } 
  };

  useEffect(() => {
    getAllCoursesList();
    getCourseSearchDateType()
  }, [activeTab, selectedFilter]);

  return (
    <Layout>
      <div className='col-12 d-inline-flex flex-column p-4'>
        <PageHeader
          title="Courses"
          filterDateType={courseSearchDateType}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          type={"filter"}
        />
        <div className='col-12 d-inline-flex flex-column mt-4'>
          <TableTabHeader
            courseTab={courseTab}
            searchPlaceholder="Search Courses"
            activeTab={activeTab}
            setSelectedFilter={setSelectedFilter}
            onTabClick={(item) => {
              setActiveTab(item);
              setSelectedFilter((prev) => ({
                ...prev,
                pageNumber: 1
              }))
            }}
          />
          <CourseList
            coursesList={coursesList}
            getAllCoursesList={getAllCoursesList}
            setActiveTab={setActiveTab}
            setSelectedFilter={setSelectedFilter}
          />
        </div>
      </div>
    </Layout>
  );
};