import React, { useState } from 'react';
import styles from './Layout.module.css';
import { SideBar } from '../SideBar/SideBar';
import { Header } from '../Header/Header';

export const Layout = ({children, nav}) => {
    const [sideBarHide, setSideBarHide] = useState(true);

    return (
        <React.Fragment>
            <div className='col-12 d-inline-flex align-items-start overflow-hidden'>
                <div className={`${styles.leftTabPannel} ${sideBarHide === false && styles.hideAsideBar} overflow-hidden position-fixed top-0 bottom-0 start-0`}>
                    <SideBar nav={nav} />
                </div>
                <div className={`${styles.rightContainer} ${sideBarHide === false && styles.hideAsideBar } min-vh-100 col-12 d-inline-flex flex-column`}>
                    <Header setSideBarHide={setSideBarHide} sideBarHide={sideBarHide} />
                    <div className='col-12 d-inline-flex'>
                        <div className='container-xxl'>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}