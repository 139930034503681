import React, { useEffect, useRef, useState } from "react";
import styles from './PageHeader.module.css';
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";

export const PageHeader = ({ title, filterDateType, selectedFilter, setSelectedFilter, type }) => {
    const dateRef = useRef(null);
    const [date, setDate] = useState(new Date());

    const changeDate = (val) => {
        let fromDate = new Date(val[0]),
            toDate = '';
        if (val[1] !== undefined) {
            toDate = new Date(val[1]);
        }
        if (fromDate !== null && toDate !== null && fromDate !== undefined && toDate !== undefined && toDate !== '') {
            let fromDay = fromDate.getDate(),
                fromMonth = fromDate.getMonth() + 1,
                fromYear = fromDate.getFullYear(),
                toDay = toDate.getDate(),
                toMonth = toDate.getMonth() + 1,
                toYear = toDate.getFullYear();

            if (fromDay < 10) {
                fromDay = '0' + fromDay;
            }
            if (toDay < 10) {
                toDay = '0' + toDay;
            }
            if (fromMonth < 10) {
                fromMonth = '0' + fromMonth;
            }
            if (toMonth < 10) {
                toMonth = '0' + toMonth;
            }

            if (fromYear + '-' + fromMonth + '-' + fromDay !== toDay + '-' + toMonth + '-' + toYear) {
                // setDate(fromYear + '-' + fromMonth + '-' + fromDay + ' to ' + toDay + '-' + toMonth + '-' + toYear);
                const startDateString = `${fromYear}-${fromMonth}-${fromDay}`;
                const endDateString = `${toYear}-${toMonth}-${toDay}`;
                setSelectedFilter((prev) => ({
                    ...prev,
                    start_date: startDateString,
                    end_date: endDateString,
                    pageNumber: 1
                }));
            } else {
                const startDateString = `${fromYear}-${fromMonth}-${fromDay}`;
                const endDateString = `${fromYear}-${fromMonth}-${fromDay}`;
                // setDate(fromYear + '-' + fromMonth + '-' + fromDay);
                setSelectedFilter((prev) => ({
                    ...prev,
                    start_date: startDateString,
                    end_date: endDateString,
                    pageNumber: 1
                }));
            }
        }
    }

    // useEffect(() => {
    //     console.log(dateRef);
    // }, []);
    return (
        <React.Fragment>
            <div className={`${styles.pageHeader} col-12 d-inline-flex align-items-center justify-content-between`}>
                <h1 className={`${styles.pageTitle} d-inline-flex`}>{title}</h1>
                <div className='col-3 position-relative'>
                    {type === "filter" ?
                        <CustomDateRangePicker
                            courseSearchDateType={filterDateType}
                            selectedFilter={selectedFilter}
                            setSelectedFilter={setSelectedFilter}
                        />
                        :
                        <Flatpickr
                            data-enable-time
                            placeholder="Select Date"
                            options={{ altInput: true, enableTime: false, dateFormat: "Y-m-d", maxDate: "today", mode: "range" }}
                            ref={dateRef}
                            onChange={(e, [date]) => changeDate(e, [date])}
                            className={`${styles.searchInput} d-inline-flex col-12`}
                        />
                    }
                </div>
            </div>
        </React.Fragment>
    );
}