import { axiosInstance } from "../ApiUrls/Interceptors";
import { ApiUrls } from "../ApiUrls/apiUrls";

/**
 * 
 * @param {Object} filters 
 * @returns @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get all the Alert List
 */
export const getAlertList = async (filters) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_ALERT_LIST}`,
      {
        params: filters
      });

    return {
      status: true,
      rows: result?.data?.data?.list?.rows,
      data: result?.data?.data?.list?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}

/**
 * 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get all Const Data related to Alert
 */
export const getAlertConstData = async () => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_ALERT_CONST_DATA}`);

    return {
      status: true,
      data: result?.data?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}

/**
 * 
 * @param {Object} data 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Create the Alert message
 */
export const createAlert = async (data) => {
  try {
    const result = await axiosInstance.post(`${ApiUrls.CREATE_ALERT}`, data);

    return {
      status: true,
      data: result?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}

/**
 * 
 * @param {Number} id 
 * @returns 
 */
export const deleteAlert = async (id) => {
  try {
    const result = await axiosInstance.delete(`${ApiUrls.DELETE_ALERT}/${id}`);

    return {
      status: true,
      data: result?.data?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}

/**
 * 
 * @param {Number} id 
 * @returns @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Get Alert Details
 */
export const getAlertDetails = async(id) => {
  try {
    const result = await axiosInstance.get(`${ApiUrls.GET_ALERT_DETAILS}/${id}`)
    return {
      status: true,
      data: result?.data?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}

/**
 * 
 * @param {Number} id 
 * @param {Object} data 
 * @returns {Promise<{status: boolean, error: *}|{status: boolean, body: *}>}
 * @comment Update the Alert Details
 */
export const updateAlertDetails = async (id, data)=> {
  try {
    const result = await axiosInstance.post(`${ApiUrls.UPDATE_ALERT_DETAILS}/${id}`, data);

    return {
      status: true,
      data: result?.data,
      msg: result?.data?.message
    }
  } catch (error) {
    return {
      status: false,
      error: error?.message
    }
  }
}