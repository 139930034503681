import React from 'react';
import styles from './UserList.module.css';
import CommonTable from '../CommonTable/CommonTable';
import { calculateTimeDifference, formatDate, formatDateTime, formatTime } from '../../utils/DateTimeFormat';
import { updateUserState } from '../../services/user.service';
import toast from 'react-hot-toast';
import CustomPagination from '../../common/CustomPagination';

export const UserList = ({ usersList, getUsersList, setSelectedFilter }) => {

  const handleChangeStatus = async ({ status, id }) => {
    const data = {
      status: id,
      user_id: status?.id,
    };
    const result = await updateUserState(data);

    if (result.status) {
      getUsersList();
      toast.success(result?.msg)
    } else {
      toast.error(result?.error);
    }
  }

  const columns = [
    {
      name: 'Full Name',
      id: 'full_name',
      sortable: false,
      span: 2,
      renderer: (usersList) => (
        <div className={`col-12 d-inline-flex align-items-center col-12 gap-3`}>
          <span className={`${styles.courseImag} flex-shrink-0 d-inline-flex align-items-center justify-content-center`}>{usersList?.full_name?.charAt(0)}</span>
          <div className='flex-grow-1 d-flex flex-column'>
            <h6 className={`${styles.courseName} col-12 text-truncate`}>{usersList?.full_name}</h6>
            <span className={`${styles.courseTiming} d-inline-flex col-12`}>{formatDate(usersList?.createdAt) + " " + formatTime(usersList?.createdAt)}</span>
            <span className={`${styles.courseTimeDifference} d-inline-flex col-12`}>{calculateTimeDifference(usersList?.createdAt)}</span>
          </div>
        </div>
      )
    },
    {
      name: 'Email',
      id: 'email',
      renderer: (usersList) => (
        <div className='col-12 d-inline-flex align-items-center gap-2'>
          <span className={`${styles.authorName} d-inline-flex`}>{usersList?.email}</span>
        </div>
      )
    },
    {
      name: 'Role',
      id: 'role_name',
      renderer: (usersList) => (
        <div className='col-12 d-inline-flex align-items-center gap-2'>
          <span className={`${styles.authorName} d-inline-flex`}>{usersList?.role_name}</span>
        </div>
      )
    },
    {
      name: 'Phone',
      id: 'phone',
      renderer: (usersList) => (
        <div className='col-12 d-inline-flex align-items-center gap-2'>
          <span className={`${styles.authorName} d-inline-flex`}>{usersList?.phone}</span>
        </div>
      )
    },
    {
      name: 'Status',
      id: 'Status',
      renderer: (status) => (
        <span className={`${styles.tableData}`}>
          <span className={`${styles.courseStatus} ${status?.status_name === "Inactive" ? styles.coursePending : status?.status_name === "Active" ? styles.courseLive : styles.courseRejected} d-inline-flex`}></span> {status?.status_name}
        </span>
      )
    },
    {
      name: 'Action',
      id: 'action',
      renderer: (status) => (
        <div className='col-12 d-inline-flex align-items-center gap-2'>
          {status?.status_name === "Active" ?
            <React.Fragment>
              <span role='button' className={`${styles.rejectBtn} d-inline-flex align-items-center justify-content-center px-3`} onClick={() => handleChangeStatus({ status, id: 2 })} >InActive</span>
              {/* <span role='button' className={`${styles.suspended} d-inline-flex align-items-center justify-content-center px-3 py-3`} onClick={() => handleChangeStatus({ status, id: 3 })} >Account Suspend</span> */}
            </React.Fragment>
            :
            <React.Fragment>
              <span role='button' className={`${styles.acceptBtn} d-inline-flex align-items-center justify-content-center px-3`} onClick={() => handleChangeStatus({ status, id: 1 })} >Active</span>
              {/* <span role='button' className={`${styles.suspended} d-inline-flex align-items-center justify-content-center px-2`} onClick={() => handleChangeStatus({ status, id: 3 })}>Account Suspend</span> */}
            </React.Fragment>
          }
        </div>
      )
    }
  ];

  return (
    <React.Fragment>
      <div className={`${styles.courseListContainer} d-inline-flex flex-column overflow-hidden`}>
        <CommonTable cols={columns} rows={usersList?.data} />
        <div className='px-4'>
          <CustomPagination
            totalCount={usersList?.rows}
            onChange={(n) => {
              setSelectedFilter((prev) => ({
                ...prev,
                pageNumber: n
              }))
            }}
            activePage={1}
          />
        </div>
      </div>
    </React.Fragment>
  )
}