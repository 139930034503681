import React from 'react';
import styles from './exclusiveServiceModal.module.css'
import { formatDate, formatTime } from '../../../utils/DateTimeFormat';

const ExclusiveServiceModal = ({ setIsOpen, listData }) => {
  return (
    <React.Fragment>
      <div className="modal-backdrop fade show"></div>
      <div className="modal d-inline-block" id="exampleModal" role="dialog" aria-hidden="false">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{`View Detail`}</h5>
              <button type="button" onClick={() => setIsOpen(false)} className={`${styles.closes} close`} data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className={`${styles.descriptionBody} modal-body py-2`}>
              <div className="row">
                <div className={`${styles.basicInfo} col-4`}>
                  <p className="">Company Bussiness Name :-</p>
                </div>
                <div className={`${styles.basicDiscription} col-8`}>
                  <p className="">{listData?.company_bussiness_name}</p>
                </div>
                <div className={`${styles.basicInfo} col-4`}>
                  <p className="">Course Category :-</p>
                </div>
                <div className={`${styles.basicDiscription} col-8`}>
                  <p className="">{listData?.course_category_name}</p>
                </div>
                <div className={`${styles.basicInfo} col-4`}>
                  <p className="">Start Date :-</p>
                </div>
                <div className={`${styles.basicDiscription} col-8`}>
                  <p className="">{formatDate(listData?.createdAt) + " " + formatTime(listData?.createdAt)}</p>
                </div>
                <div className={`${styles.basicInfo} col-4`}>
                  <p className="">End Date :-</p>
                </div>
                <div className={`${styles.basicDiscription} col-8`}>
                  <p className="">{formatDate(listData?.updatedAt) + " " + formatTime(listData?.updatedAt)}</p>
                </div>
                <div className={`${styles.basicInfo} col-4`}>
                  <p className="">Courses Type :-</p>
                </div>
                <div className={`${styles.basicDiscription} col-8`}>
                  <p className="">{listData?.course_type}</p>
                </div>
                <div className={`${styles.basicInfo} col-4`}>
                  <p className="">Email :-</p>
                </div>
                <div className={`${styles.basicDiscription} col-8`}>
                  <p className="">{listData?.email}</p>
                </div>
                <div className={`${styles.basicInfo} col-4`}>
                  <p className="">Delivery Name :-</p>
                </div>
                <div className={`${styles.basicDiscription} col-8`}>
                  <p className="">{listData?.delivery_method_name}</p>
                </div>
                <div className={`${styles.basicInfo} col-4`}>
                  <p className="">Message :-</p>
                </div>
                <div className={`${styles.basicDiscription} col-8`}>
                  <p className="">{listData?.message}</p>
                </div>
                <div className={`${styles.basicInfo} col-4`}>
                  <p className="">User Detail :-</p>
                </div>
                <div className={`${styles.basicDiscription} col-8`}>
                  <p className="">Email:- {listData?.user_email}</p>
                  <p className="">
                    Name:- {listData?.user_name}
                  </p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <span role='button' className={`${styles.close} d-inline-flex align-items-center justify-content-center px-3`} onClick={() => setIsOpen(false)} >Close</span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ExclusiveServiceModal